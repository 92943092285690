<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="prosesing">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando...</p>
        </div>
    </div>
    <div class="tatacuy-config pl-3 pr-3" v-else>
        <p class="tx-special-green text-center">Configuración de mi Tata Cuy</p>
        <div>
            <small class="tx-bluegray-lighten">1/2 - Tokens por premiar</small>
            <hr class="br-bluegray-lighten mb-1">
        </div>
        <div v-if="!next">
            <div class="form-group">
                <label for="">Cantidad de tokens a premiar ($PCUY)</label>
                <div class="form-control">
                    <input
                        name="tokens"
                        type="number"
                        placeholder="$PCUY"
                        v-model="totalAmount"
                        @focusout="pcuyToSami"
                    />
                </div>
            </div>

            <div class="row gap-1">
                <div class="form-group col-6">
                    <label for="">Valor de cada premio ($PCUY)</label>
                    <div class="form-control">
                        <input
                            name="tokens"
                            type="number"
                            placeholder="$PCUY"
                            v-model="amoutPerUser"
                        />
                    </div>
                </div>
                <div class="form-group col-6">
                    <label for="">Cuyes ganadores</label>
                    <div class="form-control">
                        <input
                            name="tokens"
                            type="number"
                            placeholder="0"
                            v-model="totalWinners"
                            readonly
                        />
                    </div>
                    <small class="mt-1 d-block tx-danger" v-if="isFloat(totalWinners)">El valor debe ser entero</small>

                </div>
            </div>
            <small class="tx-bluegray-lighten">Los {{totalAmount}} PCUYS que estás invirtiendo, significarán {{calcSamis}} Puntos Samis repartidos entre tus ganadores.</small>
        </div>
        <div class="tx-white" v-else>
            <p class="mb-1">Completa los requisitos que los cuyes visitantes deben cumplir para ganar el premio:</p>
            <ul class="requeriments-list">
                <li>
                    <input type="number" placeholder="Cant. de" v-model="requeriments.conditionMinVisitPacha"> 
                    días de visita
                </li>
                <li>
                    <input type="number" placeholder="Cant. de" v-model="requeriments.conditionMinPcuySpentPacha"> 
                    dólares invertidos en mi pacha
                </li>
                <li>
                    <input type="number" placeholder="Cant. de" v-model="requeriments.conditionMinViewAdvertisingPacha"> 
                    clics en la publicidad de la Pacha
                </li>
            </ul>
        </div>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @action="handleClick" :disabled="blockedBtn">
                {{ mainButton }}
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="emit('close')">
                Configurarlo luego
            </ButtonComponent>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import axios from "axios";
import { computed, defineEmits, ref, defineProps, onMounted, onBeforeUnmount } from "vue";
import { API_URL } from '@/shared/constants'
import useWeb3 from "@/shared/composables/useWeb3";
import { utils } from "ethers";
import { usePachaStore } from "@/modules/pacha/store";
import useLogin from "@/modules/Login/composables/useLogin";
import { 
    startTatacuyCampaign,    
} from "pachacuy-sc";
import { convertPcuyToSami } from "pachacuy-info";


const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const prosesing = ref(false)
const { getWalletData } = useLogin()

const emit = defineEmits(["close", "result", "closeState"])

const next = ref(false);
const loading = ref(false);

const totalAmount = ref(0)
const amoutPerUser = ref(0)
const totalWinners = computed(() => {
    const result = totalAmount.value / amoutPerUser.value;
    return result === Infinity ? 0 : result || 0;
})

const requeriments = ref ({
    conditionMinVisitPacha: 0,
    conditionMinPcuySpentPacha: 0,
    conditionMinViewAdvertisingPacha: 0
})

const isFloat = (value: number) => {
    return Number(value) === value && value % 1 !== 0;
}

const mainButton = computed(() => {
    return !next.value ? "Siguiente paso" : "Confirmar datos";
});

const { getSigner } = useWeb3();
const signer = getSigner();
const pachaStore = usePachaStore();

const totalSami = ref(0);
const rewardSami = ref(0);

const handleClick = async () => {
    if(!next.value) {
        next.value = true;
        return
    }

    try {
        const totalSamiValue = await convertPcuyToSami(
            utils.parseEther(String(totalAmount.value))
        );

        const rewardSamiValue = await convertPcuyToSami(
            utils.parseEther(String(amoutPerUser.value))
        );

        totalSami.value = parseFloat(totalSamiValue.toString());
        rewardSami.value = parseFloat(rewardSamiValue.toString());

    } catch (error: any) {
        // console.log(error)
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : "Ocurrió un error, intenta de nuevo",
            type: "bad",
        })
        return
    }
    
    try {
        prosesing.value = true;
        emit('closeState',false)
        let response;
        try{
            response = await startTatacuyCampaign(
                signer,
                parseInt(props.business.businessTokenId),
                utils.parseEther(String(totalAmount.value)),
                utils.parseEther(String(amoutPerUser.value))
            )
        }catch (error:any) {
         emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : 'Ocurrió un error, por favor intenta de nuevo',
            type: "bad",
        })
        return
    }
        if(response){
            const resp = await axios({
                method: "post",
                url: `${API_URL}/tatacuy/gifts`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`
                },
                data: {
                    "giftPcuysTotal": totalAmount.value,
                    "giftSamiPointsTotal": totalSami.value,
                    "giftSamiPointsByCuy": rewardSami.value,
                    "businessId": props.businessId,
                    ...requeriments.value
                }
            })
            if(resp.data){
                emit('result', {
                    show: true,
                    title: "¡Muy bien!",
                    message: "Has comprado un Tata Cuy y ahora ya es parte de tu inventario",
                    type: "success",
                })
                await getWalletData()
            }
        }
    } catch (error) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: "Ha ocurrido un error al configurar tu Tata Cuy, inténtalo de nuevo",
            type: "bad",
        })
    }
}

onMounted(() => {
    if(props.business.gift !== null && props.business.gift.isOn) emit('close')
})

const blockedBtn = computed(() => {
    
    return (
        isFloat(totalWinners.value) || 
        totalWinners.value <= 0 ||
        loading.value
    )

})

const calcSamis = ref(0)

const pcuyToSami = async() => {
    calcSamis.value = '...'
    const resp = await convertPcuyToSami(
        utils.parseEther(String(totalAmount.value)),
    )
    calcSamis.value = resp
}

</script>