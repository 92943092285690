<template>
    <div class="buy-modal">
        <PixelFrame :styles="frameStyles">
            <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
                <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
                <div class="loading mb-2 mt-2">
                    <img src="@/shared/assets/img/loader.png" alt="">
                    <p>Procesando...</p>
                </div>
            </div>
            <div class="buy-modal-content tx-white text-center" v-else>
                <img src="@/modules/pacha/assets/img/cuy-token.png" class="pcuy-icon">
                <p class="mb-2" v-html="sentences.approved.message">
                </p>
                <p>{{sentences.approved.quest}}
                </p>
                <div class="buttons-group mt-3">
                    <ButtonComponent type="primary" @action="primaryClick" :disabled="loading">
                        {{ loading ? 'Procesando...' : 
                            sentences.approved.button
                        }}
                    </ButtonComponent>
                    <ButtonComponent type="secondary" @action="emit('handleClose')" :disabled="loading">
                        Cancelar
                    </ButtonComponent>
                </div>
            </div>
        </PixelFrame>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>

<script lang="ts" setup>
import { ref, defineProps, defineEmits} from "vue";
import ButtonComponent from "../ButtonComponent/ButtonComponent.vue";
import useWeb3 from '@/shared/composables/useWeb3'

const props = defineProps({
    amount : {
        type: Number,
        default: 0
    },
    divisa : {
        type: String,
    },
    product : {
        type: String,
    },
    approved : {
        type: Boolean,
        default: false
    }
});

const { approveAmount } = useWeb3()

const emit = defineEmits(["handleClick", "handleClose"]);

const approved = ref(props.approved);
const loading = ref(false);

const approve = async() => {
    loading.value = true;
    const aproved = await approveAmount(props.amount);
    approved.value = true;
    loading.value = false;
}

const primaryClick = async() => {
    loading.value = true;
    try {
        emit("handleClick");
    } catch (error) {
        alert('Algo ha salido mal, intenta de nuevo')
        loading.value = false;
    }
}

const sentences = {
    'approved': {
        button: props.amount ? 'Transferir monto' : 'Reclamar',
        message: `
        El monto de <b class="tx-yellow">${props.amount} ${props.divisa}</b> para la compra de <br> ${props.product} <b class="tx-yellow">ha sido validado</b>.
        `,
        quest: '¿Deseas confirmar la transferencia?'
    },
}

const frameStyles = ref({
  frameBg: "bluegray",
  contentBg: "bluegray-dark",
});

</script>