<template>
    <div class="pl-3 pr-3 tx-white qhatu-content">
        <img :src="tempImg[temp]" class="tempo" v-if="isFirst">
        <p class="tx-special-green text-center mb-1">¡Obtén recompensas por visitar los enlaces!</p>
        <img :src="toBase64(business.advertising.image.data)" alt="">
        <div class="d-flex buttons gap-2">
            <ButtonComponent type="primary" :disabled="blockedBtn" @action="winnerAdvertising" v-if="isFirst">
                ¡Acumula {{business.advertising.cuySamiPointsReceive}} sami!
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="redirectAdvertising">
                Visitar enlace
            </ButtonComponent>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { defineEmits, defineProps, onMounted, onUnmounted, ref, watch } from "vue";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";
import axios from "axios";
import { API_URL } from "@/shared/constants";
import { usePachaStore } from "@/modules/pacha/store";
import temp5 from "./img/5.png";
import temp4 from "./img/4.png";
import temp3 from "./img/3.png";
import temp2 from "./img/2.png";
import temp1 from "./img/1.png";

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const emit = defineEmits(["close", "result"])
const temp = ref(4);
const blockedBtn = ref(true);
const guineaPigStore = useGuineaPigStore()
const adId = ref(0);
const isFirst = ref(false);

onMounted(async () => {
    await viewAdvertising();
    setTimeout(() => {
        temp.value = temp.value - 1
    }, 1000);
    document.addEventListener('visibilitychange', visibility);
})

onUnmounted(() => {
    document.removeEventListener('visibilitychange', visibility);
})

const visibility = () => {
    if(document.visibilityState === 'hidden'){
        countDown.value = false;
    }else{
        countDown.value = true;
        setTimeout(() => {
            temp.value = temp.value - 1
        }, 1000);
    }
}

const viewAdvertising = async () => {
    const resp = await axios({
        method: "post",
        url: `${API_URL}/qhatu-wasi/advertising-view`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
        },
        data: {
            "advertisingId": props.business.advertising.id,
            "avatarId": guineaPigStore.guineaPigSelected.id,
        }
    })
    adId.value = resp.data.id
    isFirst.value = resp.data.isFirst
}

const finishAdvertising = async () => {
    const resp = await axios({
        method: "put",
        url: `${API_URL}/qhatu-wasi/advertising-view/${adId.value}/finish/${guineaPigStore.guineaPigSelected.id}/avatar`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
        },
    })
    if(resp){
        blockedBtn.value = false
    }
}

const winnerAdvertising = async () => {
    try {
        const resp = await axios({
            method: "put",
            url: `${API_URL}/qhatu-wasi/advertising-view/${adId.value}/winner/${guineaPigStore.guineaPigSelected.id}/avatar`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
            },
        })
        if(resp){
            guineaPigStore.updateCuySamiPoints(
                guineaPigStore.guineaPigSelected.samiPoints +
                props.business.advertising.cuySamiPointsReceive
            )
            emit('result', {
                show: true,
                title: "¡Muy bien!",
                message: `Has ganado ${props.business.advertising.cuySamiPointsReceive} puntos sami`,
                type: "success",
            })
        }
    } catch (error) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: `La campaña ha finalizado`,
            type: "bad",
        })
    }
}

const redirectAdvertising = async () => {
    window.open(props.business.advertising.link, '_blank')
    const resp = await axios({
        method: "put",
        url: `${API_URL}/qhatu-wasi/advertising-view/${adId.value}/redirect/${guineaPigStore.guineaPigSelected.id}/avatar`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
        },
    })
    // console.log(resp)
}

function toBase64(arr: any) {
   arr = new Uint8Array(arr)
   return window.atob(window.btoa(
      arr.reduce((data:any, byte:any) => data + String.fromCharCode(byte), '')
   ));
}

const tempImg = [temp1, temp2, temp3, temp4, temp5]

const countDown = ref(true)

watch(temp, (val: any) => {
    if(val < 0) {
        finishAdvertising()
        return
    }
    if(countDown.value){
        setTimeout(() => {
            temp.value = temp.value - 1
        }, 1000);
    }
})

</script>