import Phaser from 'phaser'


export default class EventsController {
    private static instance: EventsController;
    public scene:any;
    public emiter:any;

    constructor(){
        this.emiter = new Phaser.Events.EventEmitter()
    }

    public static getInstance(): EventsController {
        if (!EventsController.instance) {
            EventsController.instance = new EventsController();
        }

        return EventsController.instance;
    }
    init(scene:any){
        this.scene = scene;
    }
    destroy(){
        this.emiter.removeAllListeners();
    }
}