import axios from "axios";
import { API_URL } from '@/shared/constants'
const token = localStorage.getItem('TOKEN')

export const createHatunWasi = async (message:string, businessId:number) => {
    const resp = await axios({
        method: "post",
        url: `${API_URL}/welcome`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            welcomeText: message,
            businessId: businessId
        }
    })

    return resp
}

export const updateHatunWasi = async (message:string, businessId:number) => {
    const resp = await axios({
        method: "put",
        url: `${API_URL}/welcome/${businessId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            welcomeText: message,
            isOn: true
        }
    })

    return resp
}