import { defineStore } from "pinia";

export const useGameStore = defineStore("game", {
  state: () => ({
    affectation: 0,
    boxRemaining: 0,
    maxSpecilalBoxesOpenedByCuy: 0,
    aviableGame: false,
    chat: {
      usersActive: [],
      messages: [],
    },
    audio: {
      music: localStorage.getItem('audio') ? parseInt(JSON.parse(localStorage.getItem('audio') ?? '').music) : 50,
      sound: localStorage.getItem('audio') ? parseInt(JSON.parse(localStorage.getItem('audio') ?? '').sound) : 50,
      general: localStorage.getItem('audio') ? parseInt(JSON.parse(localStorage.getItem('audio') ?? '').general) : 50,
    }
  }),
  getters: {
    musicVolume: (state) => state.audio.music / 100,
    soundVolume: (state) => state.audio.sound / 100,
  },
  actions: {
    setAffectation(affectation: number) {
      this.affectation = affectation;
    },
    setBoxRemaining() {
      this.boxRemaining = this.boxRemaining - 1;
    },
    setMaxSpecilalBoxesOpenedByCuy(maxSpecilalBoxesOpenedByCuy: number) {
      this.maxSpecilalBoxesOpenedByCuy = maxSpecilalBoxesOpenedByCuy;
    },
    setInitialState(state: any) {
      this.affectation = state.affectation;
      this.boxRemaining = state.boxRemaining;
      this.maxSpecilalBoxesOpenedByCuy = state.maxSpecilalBoxesOpenedByCuy;
    },
    setUsersChat(users: any) {
      this.chat.usersActive = users;
    },
    setMessageChat(messages: any) {
      this.chat.messages = messages;
    },
    setAviableGame(aviableGame: boolean) {
      this.aviableGame = aviableGame;
    }
  },
});
