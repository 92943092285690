<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Calculando Probabilidad</p>
        </div>
    </div>
    <div class="tatacuy-content pl-3 pr-3 tx-white" v-else>

        <p class="tx-special-green text-center mb-1">
            {{
                giftState == 1 ? probability+'% de probabilidades de ganar'
                : giftState == 2 ? '¡Probemos tu suerte!'
                : win ?'¡Felicidades!' : '¡Lo sentimos!'
            }}
            
        </p>
        <p class="text-center mb-2" v-if="giftState == 1">
            Prueba tu suerte y veamos si los 
            <b class="tx-yellow">Apus de Pachacuy te dan la</b> 
            oportunidad de ser uno de los ganadores
        </p>
        <p class="tx-special-green text-center" v-if="giftState == 1">
            ¿Estás listo(a)?
        </p>

        <div class="tatacuy-content-prize">
            <div class="win-sami" v-if="giftState == 3">
                <img src="./img/DESTELLOS.png" alt="" v-if="win">
                <img src="./img/sami.png" alt="" v-if="win">
                <img src="./img/DECEPCIONADO.png" alt="" v-if="!win">
            </div>
            <div class="tatacuy-animation" :class="!loadingBox && 'loading-box'" v-else></div>

        </div>

        <p class="text-center mb-1" v-if="giftState == 3">
        {{
            win ? `Has ganado ${winValue} puntos Sami`
            : '¡Oh no, no hubo premio!'
        }}
            
        </p>
        <ButtonComponent type="primary" @click="emit('close')" v-if="giftState == 3">
            Aceptar y jugar
        </ButtonComponent>

        <div class="buttons-group mt-1" v-if="giftState == 1">
            <ButtonComponent type="primary" @action="handleClick" :disabled="expect">
                Probar mi suerte
            </ButtonComponent>
            <ButtonComponent type="secondary" @click="emit('close')">
                Ahora no
            </ButtonComponent>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { onUpdated, defineEmits, defineProps, onBeforeMount, ref } from 'vue';
import axios from "axios";
import { API_URL } from '@/shared/constants'
import { storeToRefs } from "pinia";
import { usePachaStore } from "@/modules/pacha/store";
import useWeb3 from "@/shared/composables/useWeb3";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { 
    signTatacuyTxAndVerify,
    init
 } from "pachacuy-sc";
import { log } from 'console';
import { BigNumber } from 'ethers';
import EventsController from "@/modules/game/components/scenes/mainLevel/controllers/eventsController";

const pachaStore = usePachaStore();
const { pachaBusiness, pachaVisited } = storeToRefs(pachaStore);
const guineaPigStore = useGuineaPigStore();

const emit = defineEmits(["close", "result", "closeState"])

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    }
})

const win = ref(false)
const winValue = ref(0)

onBeforeMount(() => {    
    EventsController.getInstance().emiter.on("TatacuyTryMyLuckResult", TatacuyTryMyLuckResult)
    initGame()

})

const TatacuyTryMyLuckResult = async (
    account:any,
    hasWon:any,
    prizeWinner:any,
    likelihood:any,
    pachaUuid:any,
    tatacuyUuid:any,
    pachaOwner:any,
    idFromFront:any
) => {
    win.value = hasWon;
    winValue.value = BigNumber.from(prizeWinner).toNumber();
    console.log(prizeWinner.toString())
    await updateTatacuyGift(hasWon, BigNumber.from(idFromFront).toNumber())
    guineaPigStore.updateCuySamiPoints(
        guineaPigStore.guineaPigSelected.samiPoints + winValue.value
    )
    loadingBox.value = false;
    setTimeout(() => {
        giftState.value = 3;
    }, 2000);
    guineaPigStore.setDaysUntilDeath(guineaPigStore.guineaPigSelected.id)
}

const updateTatacuyGift = async(result: boolean, id:number) => {
    await axios({
        method: "put",
        url: `${API_URL}/tatacuy/gift-participations/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`
        },
        data: {
            isWinner: result,
            samiPoints: winValue.value
        }
    })
    // console.log(response)
}

const giftState = ref(1)
const probability = ref(0)
const loading = ref(true)

const { getSigner } = useWeb3();
const signer = getSigner();

const loadingBox = ref(true)

const handleClick = async() => {
    giftState.value = 2;
    try {
        const response = await axios({
            method: "post",
            url: `${API_URL}/tatacuy/gift-participations`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`
            },
            data: {
                giftId: props.business.gift.id,
                avatarId: parseInt(guineaPigStore.guineaPigSelected.id)
            }
        })
        if(response.data){
            timestamp.value = parseInt(response.data.timestampCreate)
        }
    } catch (error) {
        // console.log(error)
    }
    try {
        emit('closeState',false)
        const resp = await signTatacuyTxAndVerify(
            signer,
            parseInt(guineaPigStore.guineaPigSelected.cuyTokenId),
            (probability.value / 10),
            timestamp.value,
            parseInt(props.business.businessTokenId)
        );
        if(resp.result === "false"){
            emit('result', {
                show: true,
                title: "¡Oh no!",
                message: 'No cumples con todas las condiciones para participar',
                type: "bad",
            })
        }
    } catch (error:any) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : 'Ocurrió un error, por favor intenta de nuevo',
            type: "bad",
        })
        return
    }
}

const timestamp = ref(0)
const expect = ref(0)
const finishExpect = ref('')

const initGame = async() => {
    try {
        const response = await axios({
            method: "get",
            url: `${API_URL}/tatacuy/gifts/${props.business.id}/probability/${guineaPigStore.guineaPigSelected.id}/avatar`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`
            }
        })
        if(response.data) {
            probability.value = response.data.probability
            expect.value = parseInt(response.data.expect)
            finishExpect.value = response.data.finishExpect
        }
    } catch (error) {
        // console.log(error)
    }
    loading.value = false
}

onUpdated(() => {
    if(expect.value) {
        emit('result', {
            show: true,
            title: "¡Aún es algo pronto!",
            message: `Vuelve el 
            ${format(new Date(finishExpect.value), "iiii d 'a las' HH:mm 'hrs'", { locale: es })} para poder volver a probar tu suerte en el Tata Cuy`,
            type: "bad",
        })
    }
})
</script>