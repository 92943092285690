<template>
    <div class="pl-3 pr-3" v-if="props.pptProps.pptState === 'config'">
        <p class="tx-special-green text-center mb-2">Piedra, Papel o Tijera</p>
        <div class="form-group">
            <label for="">Diviértete jugando con:</label>
            <div class="form-control">
                <input
                    name="tokens"
                    type="text"
                    :value="props.pptProps.data[2]"
                    readonly
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Valor de la apuesta:</label>
            <div class="form-control">
                <input
                    name="tokens"
                    type="number"
                    placeholder="Samis"
                    v-model="amount"
                    min="0"
                    :max="props.pptProps.pptLimit"
                />
            </div>
        </div>
        <small class="mt-1 d-block tx-danger" v-if="InvalidAmount">La apuesta no puede mayor a {{props.pptProps.pptLimit}}</small>
        <div>
            <small class="tx-bluegray-lighten">
                *El cuy que gane el juego PPT ganara el monto el puntos sami
            </small>
        </div>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @click="challenge" :disabled="InvalidAmount">
                Enviar invitación
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="emit('pptResponse', false, 'close')">
                Cancelar
            </ButtonComponent>
        </div>
    </div>
    <div class="pl-3 pr-3 tx-white pb-2 pt-2 ppt-loading" v-if="props.pptProps.pptState === 'wait' || props.pptProps.pptState === 'wait-bet'">
        <p class="tx-special-green text-center mb-2">
            ¡Se envió una solicitud al jugador!
        </p>
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p v-if="props.pptProps.pptState === 'wait'">Esperando la confirmación del otro jugador</p>
            <p v-else>Esperando que el jugador configure la apuesta</p>
        </div>
    </div>

    <div class="pl-3 pr-3 tx-white pb-2 pt-2 text-center" v-if="props.pptProps.pptState === 'invite' || props.pptProps.pptState === 'bet'">
        <img src="./img/cuy-invitation.png" alt="">
        <p class="mb-1" v-if="props.pptProps.pptState === 'invite'">
            <b class="tx-yellow">{{props.pptProps.data[2]}}</b> te ha invitado a jugar una partida de <b class="tx-yellow">Piedra, Papel o Tijera</b>.
        </p>
        <p class="mb-1" v-else>
            <b class="tx-yellow">{{props.pptProps.data[2]}}</b> Te Ha hecho una apuesta de <b class="tx-yellow">{{props.pptProps.data[5]}} Puntos Sami</b>.
        </p>
        
        <p>
            ¿Deseas aceptar la invitación a jugar?
        </p>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @click="acceptChallenge">
                Aceptar y jugar
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="emit('pptResponse', false, 'close')">
                Rechazar
            </ButtonComponent>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { ref, defineEmits, defineProps, onMounted, computed } from "vue";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";

const emit = defineEmits(["close", "pptResponse", "closeState"])

const guineaPigStore = useGuineaPigStore()

const props:any = defineProps({
    pptProps: Object
})

const challenge = () => {
    emit('pptResponse', true, 'bet', amount.value)
    guineaPigStore.updateCuySamiPoints(
        guineaPigStore.guineaPigSelected.samiPoints - amount.value
    )
}

const acceptChallenge = () => {
    emit('pptResponse', true, props.pptProps.pptState === 'invite' ? 'config' : 'approve')
    if(props.pptProps.data[5] > 0) {
        guineaPigStore.updateCuySamiPoints(
            guineaPigStore.guineaPigSelected.samiPoints - props.pptProps.data[5]
        )
    }
}

const amount = ref(0)
const InvalidAmount = computed(() => {
    return amount.value > props.pptProps.pptLimit
})

</script>