<template>
  <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
    <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy" />
    <div class="loading mb-2 mt-2">
      <img src="@/shared/assets/img/loader.png" alt="" />
      <p>Procesando...</p>
    </div>
  </div>
  <div class="pl-3 pr-3" v-else>
    <p class="tx-special-green text-center mb-2">
      Configuración de mi Misay Wasi
    </p>
    <div>
      <div class="form-group mb-2">
        <label for="">Premio de la rifa ($USDC)</label>
        <div class="form-control">
          <input
            name="tokens"
            type="number"
            placeholder="$USDC"
            v-model="reward"
            max="{{userStore.pcuy}}"
          />
        </div>
      </div>
      <div class="row gap-1">
        <div class="form-group col-6">
          <label for="">Precio de cada ticket ($PCUY)</label>
          <div class="form-control">
            <input
              name="tokens"
              type="number"
              placeholder="$PCUY"
              v-model="price"
            />
          </div>
        </div>
        <div class="form-group col-6">
          <label for="">Fecha de la rifa</label>
          <div class="form-control">
            <input
              name="tokens"
              type="date"
              placeholder="15/04/2022"
              @change="dateTimestamp"
              :min="tomorrowDate"
            />
          </div>
        </div>
      </div>
    </div>
    <small class="mt-1 d-block tx-danger" v-if="price > userStore.pcuy"
      >el premio no puede mayor a {{ userStore.pcuy }}</small
    >
    <div>
      <small class="tx-bluegray-lighten">
        *La rifa se realizará de forma automática en la fecha establecida por el
        dueño de la Pacha.
      </small>
    </div>
    <div class="buttons-group mt-2">
      <ButtonComponent
        type="primary"
        @action="approveToMisayWasi"
        :disabled="validatePrice"
      >
        Approve $USDC
      </ButtonComponent>
      <ButtonComponent
        type="primary"
        @action="startMisayWasi"
        :disabled="validatePrice"
      >
        Transfer and save
      </ButtonComponent>
      <ButtonComponent type="secondary" @action="emit('close')">
        Configurarlo luego
      </ButtonComponent>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, ref, onMounted, computed } from "vue";
import useWeb3 from "@/shared/composables/useWeb3";
import axios from "axios";
import { API_URL } from "@/shared/constants";
import { startMisayWasiRaffle, approveUsdc } from "pachacuy-sc";
import useLogin from "@/modules/Login/composables/useLogin";
import { useUserStore } from "@/modules/user/store";
import { utils, BigNumber } from "ethers";

const { getWalletData } = useLogin();

const userStore = useUserStore();

const props = defineProps({
  businessId: {
    type: Number,
  },
  business: {
    type: Object,
  },
});

const emit = defineEmits(["close", "result", "closeState"]);

const reward = ref(0);
const price = ref(0);
const date = ref(0);
const loading = ref(false);

const dateTimestamp = (e: any) => {
  const time = new Date(e.target.value).getTime() / 1000;
  date.value = time;
};

const { getSigner } = useWeb3();
const signer = getSigner();

const approveToMisayWasi = async () => {
  let amount = BigNumber.from(`${reward.value}000000`);
  await approveUsdc(amount, signer);
};

const startMisayWasi = async () => {
  try {
    loading.value = true;
    emit("closeState", false);

    let amount = BigNumber.from(`${reward.value}000000`);
    const resp = await startMisayWasiRaffle(
      signer,
      parseInt(props.business.businessTokenId),
      amount,
      utils.parseEther(String(price.value)),
      date.value
    );
    if (resp) {
      await getWalletData();
      const response = await axios({
        method: "post",
        url: `${API_URL}/casinos`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
        },
        data: {
          businessId: props.businessId,
        },
      });
    }
    emit("result", {
      show: true,
      title: "¡Muy bien!",
      message:
        "Has configurado correctamente tu Misay Wasi, publícalo para que puedan visualizarlo",
      type: "success",
    });
  } catch (error: any) {
    emit("result", {
      show: true,
      title: "¡Oh no!",
      message: error.data
        ? error.data.message
        : "Ocurrió un error, por favor intenta de nuevo",
      type: "bad",
    });
  }
};

onMounted(() => {
  if (props.business.casino !== null && props.business.casino.isOn)
    emit("close");
});

const tomorrowDate = computed(() => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date.toISOString().split("T")[0];
});

const validatePrice = computed(() => {
  if (price.value > userStore.pcuy || price.value <= 0) return true;
  return false;
});
</script>
