import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '@/modules/Login/views/LoginView.vue'
import Pacha from '@/modules/pacha/routes'
import GuineaPigList from '@/modules/guineaPigList/routes'
import GuineaPigSelection from '@/modules/guineaPigSelection/routes'
import ConfigRoute from '@/modules/game/routes'
import { useUserStore } from '@/modules/user/store'
import useLogin from '@/modules/Login/composables/useLogin'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/exit',
    name: 'exit',
    component: () => import('@/modules/Login/views/ExitView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/pacha-creation-panel',
    name: 'pacha-creation-panel',
    component: () => import('@/modules/pacha/Views/PachaCreationPanel.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('@/modules/game/views/GameView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/config',
    ...ConfigRoute,
    meta: { requiresAuth: true }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async(to, from,) => {
  const storeUser = useUserStore()
  const { getuserData } = useLogin()

  if(to.meta.requiresAuth){
    if(!storeUser.accessToken){
      router.push('/')
    }else{
      try {
      
      await getuserData()
    } catch (error) {
      router.push('/')
      storeUser.logout();
    }
    }
  }

})

export default router
