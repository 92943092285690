<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando...</p>
        </div>
    </div>
    <div class="pl-3 pr-3 tx-white pb-2 pt-2 text-center" v-else>
        <img src="./img/no-food.png">
        <p class="fn-pixica-bold tx-yellow mt-1">Oh no, tus cultivos se han agotado</p>
        <small>No te olvides de <b class="tx-yellow">remover tu chakra</b> para que puedas usar el espacio libre en un nuevo negocio o items del panel de creación para decorar tu Pacha.</small>
        <p class="mt-1">¿Qué deseas hacer ahora?</p>
        <div class="hr-buttons mt-1">
            <button-component type="primary" @action="burn">Remover chakra</button-component>
            <button-component type="secondary" @action="emit('close')">Seguir jugando</button-component>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, defineProps, defineEmits, computed } from 'vue'
import { burnChakra } from "pachacuy-sc";
import useWeb3 from "@/shared/composables/useWeb3"; 
import axios from 'axios';
import { API_URL } from '@/shared/constants';

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const loading = ref(false)

const emit = defineEmits(["close", "result"])

onBeforeMount(() => {
    if(props.business.food.remainingFood > 0) {
        emit("close")
    }
})

const { getSigner } = useWeb3();
const signer = getSigner();

const burn = async () => {
    loading.value = true
    try {
        const result = await burnChakra(
            signer,
            parseInt(props.business.businessTokenId)
        )
        if(result){
            const resp = await axios({
                method: 'put',
                url: `${API_URL}/chakra/foods/${props.business.food.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`
                },
            })
            // console.log(resp)
        }
        emit("result", {
            show: true,
            title: "¡Muy bien!",
            message: "Has removido tu chakra exitosamente",
            type: "success",
        })
    } catch (error:any) {
        // console.log(error)
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : 'Ocurrió un error, por favor intenta de nuevo',
            type: "bad",
        })
    }
}

</script>