import { usePachaStore } from '../store'
import PachaApi from '../api/api';
import useWeb3 from '@/shared/composables/useWeb3'
import {
    purchaseLandWithPcuy
} from "pachacuy-sc";

const pachaApi = new PachaApi();

export default () => {
    const pachaStore = usePachaStore();
    const { getSigner } = useWeb3();
    const signer = getSigner();

    const getPachas = async () => {
        const response = await pachaApi.getPachas();
        if(response.status === 200){
            pachaStore.setAllPachas(response.data);
        }
    }

    const getPachaById = async (id: number) => {
        const response = await pachaApi.getPachaById(id);
        return response;
    }

    const setPrivatePacha = async (id: number, privacity: string, price?: number) => {
        const response = await pachaApi.setPrivatePacha(id, privacity, price);
        return response;
    }

    const createPachaPass = async (data: any) => {
        const response = await pachaApi.createPachaPass(data);
        return response;
    }

    const getPachaBycode = async (code: string) => {
        const response = await pachaApi.getPachaBycode(code);
        return response;
    }

    const purchasePacha = async (id: number) => {
        // await purchaseLandWithPcuy(id, signer);
    }

    const getPachaBusiness = async (id: number) => {
        const response = await pachaApi.getPachaBusinessApi(id);
        pachaStore.setPachaBusiness(response.data);
    }

    const addPachaVisits = async (id: number, cuy: number) => {
        const response = await pachaApi.addPachaVisitsApi(id, cuy);
        return response;
    }

    return{
        getPachas,
        getPachaById,
        setPrivatePacha,
        createPachaPass,
        getPachaBycode,
        purchasePacha,
        getPachaBusiness,
        addPachaVisits
    }

}