<template>
     <div class="pl-3 pr-3 pb-2 pt-2 result-modal" :class="data.type">
         <div class="close-btn cursor-pointer" @click="emit('close')">
            <img src="@/shared/assets/img/icons/close-btn.png" v-if="data.type === 'success'">
            <img src="@/shared/assets/img/icons/close-btn-blue.png" v-else>
        </div>
        <p class="tx-green fn-pixica-bold text-center">{{data.title}}</p>
        <p class="text-center">{{data.message}}</p>
        <ButtonComponent @action="emit('close')" type="primary" v-if="data.type === 'bad'">
            Aceptar y continuar
        </ButtonComponent>
     </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { ref, defineProps, defineEmits, onMounted, computed} from "vue";
import {Howl, Howler} from 'howler';
import { useGameStore } from "@/modules/game/store";
const gameStore = useGameStore()

const successSound = new Howl({
src: '/assets/sounds/success_modal.wav',
});

const badSound = new Howl({
src: '/assets/sounds/lose_game.wav',
});

onMounted(() => {
    if (props.data.type === 'success') {
        successSound.play();
        successSound.volume(gameStore.musicVolume);
    } else {
        badSound.play();
        badSound.volume(gameStore.musicVolume);
    }
});

const emit = defineEmits(["close"])
const props = defineProps({
    data: {
        type: Object
    }
})
</script>