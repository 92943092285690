<template>
  <div class="login-container">
    <img src="../assets/img/logo_w_tagline.png" alt="" class="pachacuy-logo mb-2">
    <div class="opt-buttons mb-2" :class="loading && 'loading'" v-if="true">
      <div class="spinner">
        <img :src="spinner" alt="" class="mr-1">
        <h4 class="tx-white fn-pixica-bold">Conectando</h4>
      </div>
      <div class="form-group mb-2">
        <div class="form-control mb-2">
            <input v-model="email" type="email" placeholder="Correo electrónico">
        </div>
        <div class="form-control">
            <input v-model="password" type="password" placeholder="Contraseña">
        </div>
      </div>
      <button-component type="primary" class="mb-2" @action="Login">
        Ingresar
      </button-component>
    </div>
    <div class="pcuy"></div>
    <div class="social">
        <!-- <p>Encuéntranos <br> aquí >>></p>
        <div class="social-networks">
          <a href="https://twitter.com/CuyToken" target="_blank">
            <img :src="twitter" alt="">
          </a>
          <a href="https://t.me/CuyTokenPachacuy" target="_blank">
            <img :src="telegram" alt="">
          </a>
          <a href="https://discord.com/invite/HdAF6wW6Pw" target="_blank">
            <img :src="discord" alt="">
          </a>
        </div> -->
    </div>
    <div class="metamaskLogin fn-pixica-bold cursor-pointer" v-if="hadMetamask && !loading" @click="getcuyes">
      Login with Metamask
      <img src="@/shared/assets/img/metamask.png" alt="">
  </div>
  </div>
  <BusinessConfigModal 
  v-if="configModal" 
  @close="configModal = false"
  :resultModal="resultModal"
  />
</template>

<style lang="scss" src="../assets/scss/index.scss"></style>
<script lang="ts" setup>
import ButtonComponent from "@/shared/components/ButtonComponent/ButtonComponent.vue";
import { market, twitter, telegram, discord, spinner } from '@/shared/assets/img/icons/'
import router from "@/shared/router";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";
import useLogin from '../composables/useLogin'
import { useUserStore } from '@/modules/user/store'
import { computed, onBeforeMount, onMounted, onUpdated, ref, directives } from "vue";
import BusinessConfigModal from '@/shared/components/ModalService/ModalService.vue';
import LangSwitch from "@/shared/components/LangSwitch/LangSwitch.vue";
import { connectToMumbai } from "pachacuy-sc";
import jwt_decode from "jwt-decode";
import Web3Api from "@/modules/Login/api/web3Api";
import UserApi from "@/modules/user/api/api";
import useWeb3 from "@/shared/composables/useWeb3";

const web3Api = new Web3Api();
const userApi = new UserApi();
const guineaPigStore = useGuineaPigStore();

const { handleLogin, connectToMetamask, web3Login } = useLogin()
const userStore = useUserStore();
const loading = ref(false)
const hadMetamask = ref(false)
const configModal = ref(false);
const resultModal = ref({});

const email = ref('')
const password = ref('')


onBeforeMount(() => {
  const ethereum = (window as any).ethereum;
  if(ethereum) {
    hadMetamask.value = true
  }
  
})

onMounted(() => {
  useWeb3().initSmartContracts();
})

async function Login() {
  if(email.value && password.value){
    loading.value = true
    await handleLogin(email.value, password.value)
    loading.value = false
  }
}



const getcuyes = async () => {
  loading.value = true
  try {
    const address = await web3Api.connectToMetamask();
    userStore.setAddress(address[0]);
    if(userStore.address){
        await web3Login(userStore.address)
        const user = await userApi.getUserWeb3(userStore.accessTokenWeb3)
        guineaPigStore.setGuineaPigs(user.data.avatars);
  
        const walletData = await web3Api.getWalletData(userStore.address);
        userStore.setCuyWeb3(walletData.guineaPig);
        guineaPigStore.guineaPigsData.map((cuy: any) => {
            guineaPigStore.setDaysUntilDeath(cuy.id)
        })
    }
    handleLogin(userStore.address)
  } catch (error) {
    loading.value = false
    configModal.value = true
    resultModal.value = {
      show: true,
      title: "¡Oh no!",
      message: 'Ocurrió un error, por favor intenta de nuevo',
      type: "bad",
    }
  }
}


</script>