export const home = {
    es: {
        home:{
            subtitle: 'Solicita tokens de prueba en Telegram'
        }
    },
    en: {
        home:{
            subtitle: 'Request test tokens on Telegram'
        }
    }
}