import { API_URL } from "@/shared/constants";
import axios from "axios";
import { BigNumber } from "ethers";
import { defineStore } from "pinia";

export const useUserStore = defineStore('user',{
    state: () => ({
        address: '',
        pcuy: 0,
        id: 0,
        role: '',
        userName: "",
        accessToken: localStorage.getItem('TOKEN') ?? "",
        accessTokenWeb3: "",
        walletData : {
            guineaPigs:[],
            pachaPasses: [],
            pachas: [],
        },
        blockedUsers: [],
    }),
    getters: {
        getShortAddress: (state) => {
            return state.address.slice(0,5)
        },
        getUserName : (state) => {
            return state.userName
        }
    },
    actions: {
        setAddress(address: string) {
            this.address = address;
        },
        setWalletData(walletData: any) {
            this.walletData.guineaPigs = walletData.guineaPig;
            this.walletData.pachas = walletData.pacha;
            this.pcuy = walletData.tokenBalance;
            this.walletData.pachaPasses = walletData.pachapass;
        },
        setCuyWeb3(data:any){
            this.walletData.guineaPigs = data;
        },
        setUserData(userInfo: any) {
            this.id = userInfo.id;
            this.role = userInfo.role;
            this.userName = userInfo.userName ?? "";
            localStorage.setItem('USER_ID', userInfo.id);
        },
        setAccessToken(token: string) {
            this.accessToken = token;
            localStorage.setItem('TOKEN', token);
        },
        setAccessTokenWeb3(token: string) {
            this.accessTokenWeb3 = token;
        },
        setUserName(userName: string) {
            this.userName = userName;
        },
        logout() {
            this.accessToken = "";
            this.accessTokenWeb3 = ""
            localStorage.removeItem('TOKEN');
            localStorage.removeItem('USER_ID');
        },
        async setBlockedUsers() {
            const response = await axios({
                method: "GET",
                url: `${API_URL}/user-blocked/for-me`,
                headers: {
                    application: "application/json",
                    Authorization: `Bearer ${this.accessToken}`,
                },
            })
            this.blockedUsers = response.data
        },
        setPcuy(pcuy: number) {
            this.pcuy = pcuy;
        }
    }
})