export default {
    name: "",
    component: () => import('@/shared/layout/MainLayout.vue'),
    props: { heading: true, title: 'CONFIGURACIÓN' },
    children: [
      {
        path: '/config',
        name: 'config',
        component: () => import('@/modules/game/views/ConfigurationView.vue'),
      },
      {
        path: '/config/sounds',
        name: 'sounds',
        component: () => import('@/modules/game/views/SoundsView.vue'),
      },
      {
        path: '/config/profile',
        name: 'profile',
        component: () => import('@/modules/user/views/ProfileView.vue'),
      },
      {
        path: '/config/pacha',
        name: 'pacha',
        component: () => import('@/modules/pacha/Views/PachaConfiguration.vue'),
      },
      {
        path: '/config/tickets',
        name: 'tickets',
        component: () => import('@/modules/pacha/Views/MisayWasiTicketList.vue'),
      },
      {
        path: '/config/block-user',
        name: 'block-user',
        component: () => import('@/modules/user/views/BlockUser.vue'),
      },
    ],
  }
  