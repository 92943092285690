import {
    getLandData,
    getGuineaPigWithUuid,
    getPachaWithUuid,
    getListOfNftsPerAccount,
  } from "pachacuy-sc";

import { BigNumber } from 'ethers'

interface CuyId {
    [key: string]: any
}
export default class Web3Api {
    private ethereum = (window as any).ethereum;
    private cuyId:CuyId = {
        "ANDINO":{
            "MALE": 1,
            "FEMALE": 2
        },
        "INTI":{
            "MALE": 3,
            "FEMALE": 4
        },
        "PERU":{
            "MALE": 5,
            "FEMALE": 6
        },
        "SINTETICO":{
            "MALE": 7,
            "FEMALE": 8
        }
    }

    connectToMetamask = async () => {
        if(this.ethereum) {
            const resp = await this.ethereum.request({
                method: "eth_requestAccounts",
            });
            return resp;
        }
        return null;
    }

    getWalletData = async (address: string) => {
        const resp = await getListOfNftsPerAccount(address)
        return resp;
    }

    getGuinePigData = async (GuinePig: number) => {
        const resp = await getGuineaPigWithUuid(GuinePig);
        const cuy = {
            "burningTime": BigNumber.from((<any>resp).burningDate).toNumber(),
            "cuyId": this.cuyId[(<any>resp).race][(<any>resp).gender],
            "cuyTokenId": ""+BigNumber.from((<any>resp).uuid).toString(),
            "feedingTime": BigNumber.from((<any>resp).feedingDate).toNumber(),
            "multiplier": 4,
            "samiPoints": BigNumber.from((<any>resp).samiPoints).toNumber(),
            "speed": BigNumber.from((<any>resp).speed).toNumber(),
        }
        return cuy;
    }

    getPachaData = async (pacha: number) => {
        const resp = await getPachaWithUuid(pacha);
        const pachaResult = {
            pachaTokenId: BigNumber.from((<any>resp).uuid).toString(),
            pachaId: BigNumber.from((<any>resp).location).toNumber(),
            isPublic: (<any>resp).isPublic,
        }
        return pachaResult;
    }

/*     getPachaPassData = async (address: string) => {
        const resp = await getListOfNftsPerAccount(address)
        return resp.pachapass;
    } */


}