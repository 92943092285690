<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Obteniendo los datos de tu rifa</p>
        </div>
    </div>

    <div class="pl-3 pr-3 tx-white pb-2 pt-2" v-else>
        <p class="tx-special-green text-center mb-2">Información acerca de tu rifa</p>
        <ul class="tx-bluegray-lighten data">
            <li>
                Premio:
                <b class="tx-white">{{data.rafflePrize}} $USDC</b>
            </li>
            <li>
                Cant. de participantes:
                <b class="tx-white">{{data.participants}}</b>
            </li>
            <li>
                Precio de cada ticket:
                <b class="tx-white">{{data.ticketPrice}} $PCUYS</b>
            </li>
            <li>
                Total de rifas vendidas:
                <b class="tx-white">{{data.numberTicketsPurchased}}</b>
            </li>
            <li>
                Fondos recolectados:
                <b class="tx-white">{{data.numberTicketsPurchased * data.ticketPrice}} $PCUYS</b>
            </li>
            <li>
                Fecha de inicio de la rifa:
                <b class="tx-white">{{data.campaignStartDate}}</b>
            </li>
            <li>
                Fecha final de la rifa:
                <b class="tx-white">{{data.campaignEndDate}} 10:00 am (GMT-5)</b>
            </li>
        </ul>
        <small class="tx-bluegray-lighten d-block mt-2">
            *La rifa se realizará de forma automática en la fecha establecida por el dueño de la Pacha.
        </small>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @action="emit('close')">
                Volver al juego
            </ButtonComponent>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, onMounted, ref } from "vue";
import useWeb3 from "@/shared/composables/useWeb3";
import { getMisayWasiWithUuid } from "pachacuy-sc";
import { format, parseISO } from "date-fns";

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const emit = defineEmits(["close", "result"])

const data = ref({})
const loading = ref(true)

onMounted(() => {
    getTatacuyData()
})

const getTatacuyData = async () => {
    const resp = await getMisayWasiWithUuid(
        parseInt(props.business.businessTokenId)
    )

    const endDate = new Date(parseInt(resp.campaignEndDate.toString())* 1000)
    const endDateFormat = endDate.valueOf() + endDate.getTimezoneOffset() * 60 * 1000

    const startDate = new Date(parseInt(resp.campaignStartDate.toString())* 1000)
    const startDateFormat = startDate.valueOf() + startDate.getTimezoneOffset() * 60 * 1000

    data.value = {
    campaignEndDate: 
    format(endDateFormat, "dd/MM/yyyy"),
    campaignStartDate:
    format(startDateFormat, "dd/MM/yyyy"),
    creationDate: resp.creationDate.toString(),
    hasMisayWasi: resp.hasMisayWasi,
    isCampaignActive: resp.isCampaignActive,
    participants: resp.listOfParticipants.length,
    misayWasiPrice: resp.misayWasiPrice.toString() / 10 ** 18,
    misayWasiUuid: resp.misayWasiUuid.toString(),
    numberTicketsPurchased: resp.numberTicketsPurchased.toString(),
    owner: resp.owner,
    pachaUuid: resp.pachaUuid.toString(),
    rafflePrize: resp.rafflePrize.toString() / 10 ** 6,
    ticketPrice: resp.ticketPrice.toString() / 10 ** 18,
    ticketUuid: resp.ticketUuid.toString(),
    }

    loading.value = false
}

</script>