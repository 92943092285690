import axios from "axios";
import {API_URL} from '@/shared/constants/'

export default class PachaApi {
    public async getPachas() {
        const response = await axios({
            method: "GET",
            url: `${API_URL}/pachas`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
            },
        });
        return response;
    }

    public getPachaBycode = async (code: string) => {
        const response = await axios({
            method: "GET",
            url: `${API_URL}/pachas/${code}/code`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
            },
        });
        return response;
    }
    public getPachaById = async (id: number) => {
        const response = await axios({
            method: "GET",
            url: `${API_URL}/pachas/${id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
            },
        });
        return response;
    }
    public setPrivatePacha = async (id: number, privacity: string, price?: number) => {
        const response = await axios({
            method: "PUT",
            url: `${API_URL}/properties/${id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
            },
            data: {
                "price": price,
                "privacity": privacity,
            }
        });
        return response;
    }

    public createPachaPass = async (data: any) => {
        const response = await axios({
            method: "POST",
            url: `${API_URL}/pachapas`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
            },
            data: data
        });
        return response;
    }
    public getSpecialBoxes = async () => {
        const response = await axios({
            method: "GET",
            url: `${API_URL}/special-box`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
            },
        });
        return response;
    }

    public getPachaBusinessApi = async (id: number) => {
        const resp = await axios({
            method: 'get',
            url: `${API_URL}/business/pacha/${id}/config`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("TOKEN")}`
            }
        })
        return resp;
    }
    
    public addPachaVisitsApi = async (pacha: number, cuy: number) => {
        const resp = await axios({
            method: 'post',
            url: `${API_URL}/property-visits`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("TOKEN")}`
            },
            data:{ 
                "pachaId": pacha, 
                "avatarId": cuy 
            }
        })
        return resp;
    }
}