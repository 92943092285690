<template>
    <PixelFrame :styles="
    type === 'danger' ? dangerBtn :
    type === 'primary' ? primaryBtn : secondaryBtn" class="mx-auto">
    <button class="buttonComponent cursor-pointer" :class="type" @click="!disabled && emit('action')" :disabled="disabled">
        <slot></slot>
        <div class="buttonComponent-shadow"></div>
    </button>
    </PixelFrame>
</template>

<style lang="scss" src="./button.scss" ></style>
<script lang="ts" setup>
import { ref, defineProps, defineEmits } from 'vue';
import PixelFrame from '../PixelFrame/PixelFrame.vue';

const emit = defineEmits ([
    'action'
]);

defineProps ({
    type: String,
    disabled: {
        type: Boolean,
        default: false
    }
})

const primaryBtn = ref({
    frameBg: 'black',
    contentBg: 'yellow-light',
})

const secondaryBtn = ref({
    frameBg: 'black',
    contentBg: 'bluegray-lighten',
})

const dangerBtn = ref({
    frameBg: 'black',
    contentBg: 'danger',
})
</script>