export {};

interface Process {
  env: {
    [key: string]: string | undefined;
  };
  stdout: {
    write: (str: string) => boolean;
  };
  stderr: {
    write: (str: string) => boolean;
  };
  stdin: {
    read: () => string | null;
  };
  argv: string[];
  argv0: string;
  execArgv: never[];
  execPath: string;
  abort: () => never;
  nextTick: (callback: (...args: any[]) => void, ...args: any[]) => void;
}

declare global {
  interface Window {
    process: Process;
  }
}

if (typeof window !== 'undefined' && typeof (window as any).process === 'undefined') {
  (window as any).process = {
    env: {
      NODE_ENV: 'development',
    },
    stdout: {
      write: (str: string) => {
        console.log(str);
        return true;
      },
    },
    stderr: {
      write: (str: string) => {
        console.error(str);
        return true;
      },
    },
    stdin: {
      read: () => null,
    },
    argv: [],
    argv0: '',
    execArgv: [],
    execPath: '',
    abort: () => {
      throw new Error('Process.abort is not implemented.');
    },
    nextTick: (callback: (...args: any[]) => void, ...args: any[]) => {
      setTimeout(() => callback(...args), 0);
    },
  };
}