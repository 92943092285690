import axios from "axios";
import { useUserStore } from '@/modules/user/store/index'
import {API_URL} from '@/shared/constants/'

export default class UserApi {
    Token: any;
    constructor() {
        this.Token = useUserStore()
    }
    public updateUserName = async (userName: string) => {
        const response = await axios({
            method: "PUT",
            url: `${API_URL}/users`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.Token.accessToken}`,
            },
            data: {
                userName: userName
            },
        });
        return response;
    }

    public getUser = async () => {
        const response = await axios({
            method: "GET",
            url: `${API_URL}/users`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.Token.accessToken}`,
            },
        });
        return response;
    }

    public getUserWeb3 = async (token:string) => {
        const response = await axios({
            method: "GET",
            url: `${API_URL}/users`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    }

    public getPachas = async () => {
        const response = await axios({
            method: "GET",
            url: `${API_URL}/pachas`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.Token.accessToken}`,
            },
        });
        return response;
    }

    public createPacha = async (pacha: any) => {
        const response = await axios({
            method: "POST",
            url: `${API_URL}/properties`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.Token.accessToken}`,
            },
            data: pacha,
        });
        return response;
    }
}