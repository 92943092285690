<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando...</p>
        </div>
    </div>
    <div class="pl-3 pr-3 tx-white pb-2 pt-2" v-else>
        <p class="tx-special-green text-center mb-2">Información acerca de la premiación del Tata Cuy</p>
        <ul class="tx-bluegray-lighten data">
            <li>
                Saldo total a premiar:
                <b class="tx-white">{{data.totalFundsSamiPoints}} Puntos Sami</b>
            </li>
            <li>
                Saldo restante:
                <b class="tx-white">{{restante}} Puntos Sami</b>
            </li>
            <li>
                Cant. máxima de ganadores:
                <b class="tx-white">{{maxWinners}}</b>
            </li>
            <li>
                Valor de cada premio:
                <b class="tx-white">{{data.prizePerWinnerSamiPoints}} Puntos Sami</b>
            </li>
            <li>
                Cuyes recompensados:
                <b class="tx-white">{{rewarded}}</b>
            </li>
            <li>
                Fecha de inicio:
                <b class="tx-white">{{
                    data.campaignStartDate
                }}</b>
            </li>
        </ul>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @action="emit('close')">
                Volver al juego
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="finishCampaign">
                Cancelar premiación
            </ButtonComponent>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, onMounted, ref } from "vue";
import useWeb3 from "@/shared/composables/useWeb3";
import { getTatacuyWithUuid, finishTatacuyCampaign } from "pachacuy-sc";
import { format } from "date-fns";
import useLogin from "@/modules/Login/composables/useLogin";
import axios from "axios";
import { API_URL } from "@/shared/constants";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";

const guineaPigStore = useGuineaPigStore();

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const emit = defineEmits(["close", "result"])

const data = ref({})
const loading = ref(true)

onMounted(() => {
    getTatacuyData()
})

const { getWalletData } = useLogin()

const getTatacuyData = async () => {
    const tatacuy = await getTatacuyWithUuid(props.business.businessTokenId)
    data.value = {
        campaignEndDate: tatacuy.campaignEndDate.toString(),
        campaignStartDate: 
        format(parseInt(tatacuy.campaignStartDate.toString())*1000, "dd/MM/yyyy"),
        creationDate: tatacuy.creationDate.toString(),
        hasTatacuy: tatacuy.hasTatacuy.toString(),
        isCampaignActive: tatacuy.isCampaignActive.toString(),
        owner: tatacuy.owner,
        pachaUuid: tatacuy.pachaUuid.toString(),
        prizePerWinnerSamiPoints: tatacuy.prizePerWinnerSamiPoints.toString(),
        ratePcuyToSamiPoints: tatacuy.ratePcuyToSamiPoints.toString(),
        tatacuyUuid: tatacuy.tatacuyUuid.toString(),
        totalFundsPcuyDeposited: tatacuy.totalFundsPcuyDeposited.toString(),
        totalFundsSamiPoints: tatacuy.totalFundsSamiPoints.toString(),
        totalSamiPointsClaimed: tatacuy.totalSamiPointsClaimed.toString(),
    }
    loading.value = false
}

const { getSigner } = useWeb3();
const signer = getSigner();

const finishCampaign = async () => {
    loading.value = true
    try {
        const result = await finishTatacuyCampaign(
            signer,
            props.business.businessTokenId
        )
        if(result) {
            await axios({
                method: "put",
                url: `${API_URL}/tatacuy/gifts/${props.business.gift.id}/${guineaPigStore.guineaPigSelected.id}`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
                },
                data: {
                    isOn: false
                }
            })
            guineaPigStore.updateCuySamiPoints(
                guineaPigStore.guineaPigSelected.samiPoints +
                restante.value
            )
            emit('result', {
            show: true,
            title: "¡Muy bien!",
            message: 'Has finalizado la campaña con éxito',
            type: "success",
        })
        }
    } catch (error:any) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : 'Ocurrió un error, por favor intenta de nuevo',
            type: "bad",
        })        
    }
}

const maxWinners = computed(() => {
    const max = data.value.totalFundsSamiPoints / data.value.prizePerWinnerSamiPoints
    return max !== Infinity ||max < 0 ? max : 0
})

const rewarded = computed(() => {
    const result = (data.value.totalFundsSamiPoints - restante.value) / data.value.prizePerWinnerSamiPoints
    return result !== Infinity ||result < 0 ? result : 0
})

const restante = computed(() => {
    const result = data.value.totalFundsSamiPoints - data.value.totalSamiPointsClaimed
    return result !== Infinity ||result < 0 ? result : 0
})
</script>