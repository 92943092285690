<template>
    <div class="pl-3 pr-3 tx-white pb-2 pt-2 text-center">
        <img src="./img/cuy-invitation.png" alt="">
        <p>
            CONGRATULATIONS, YOU ARE THE WINNER OF THE RAFFLE!
        </p>
        <p class="tx-yellow">
            {{winner.netPrize}} $USDC
        </p>
        <small class="tx-bluegray-lighten d-block mt-2">
            *You have been charged a tax of {{winner.feePrize}} $USDC
        </small>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @action="emit('close')">
                Keep enjoying Pachacuy
            </ButtonComponent>
            <!-- <ButtonComponent type="secondary" @action="router.push('/map')">
                Buscar más rifas
            </ButtonComponent> -->
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, defineEmits, defineProps, onMounted } from "vue";
import router from "@/shared/router";
const props = defineProps({
    winner: {
        type: Object,
    },
})

const emit = defineEmits(["close"])

</script>