import axios from 'axios';

import {API_URL} from '@/shared/constants/'
import { useGameStore } from "@/modules/game/store";

export default class LoginApi {
  public async login(email:string, password:string) {
    const response = await axios.post(`${API_URL}/auth/login/email`, {
      "email": email,
      "password": password
    });
    return response;
  }

  public async register(user: {
    "email": string,
    "password_": string
  }) {
    const resp = await axios.post(`${API_URL}/users/email`, user);
    return resp;
  }

  public async getGameAviable() {
    const resp = await axios.get(`${API_URL}/general-config/accessPachacuy`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
      }
    })
    const gameStore = useGameStore()

    gameStore.setAviableGame((resp.data.access === 'true'))
    
  }

  public async loginWeb3(address: string) {
      const response = await axios.post(`${API_URL}/auth/login`, {
        "myWalletAddress": address,
        "password": address
      });
      return response;
    }
  
    public async registerWeb3(user: {
      "myWalletAddress": string,
      "password": string
    }) {
      const resp = await axios.post(`${API_URL}/users`, user);
      return resp;
    }

}