<template>
  <div class="pixel pixel-container" :class="styles.frameBorder && 'pixel-border'" :style="styleObject">
    <div class="pixel-content">
      <slot></slot>
    </div>
    <div class="user-info" v-if="userInfo">
      <div class="address artifact">
        <span>{{getShortAddress}}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./pixel-frame.scss" ></style>
<script lang="ts" setup>
import { defineProps, ref } from "vue";
import { useUserStore } from '@/modules/user/store'

const { getShortAddress, samiPoints } = useUserStore()

const props = defineProps({
  styles: {
    type: Object,
    default: () => ({
      frameBg: null,
      frameBorder: null,
      contentBg: null,
      contentColor: null,
    }),
  },
  userInfo: {
    type: Boolean,
    default: false,
  },
});

const styleObject = ref({
  "--frame-bg": `var(--${props.styles.frameBg})`,
  "--frame-border": `var(--${props.styles.frameBorder})`,
  "--content-bg": `var(--${props.styles.contentBg})`,
  "--content-color": `var(--${props.styles.contentColor})`,
});
</script>
