<template>
    <div class="tx-white pl-3 pr-3">
        <div class="mb-2 text-center">
            <p class="tx-special-green">Alcaldía de la Pacha {{pachaStore.pachaVisited.pacha.code}}</p>
            <small class="tx-bluegray-lighten">Pacha de {{username}}</small>
        </div>

        <small>Mensaje(s) o comunicado(s):</small>
        <div class="hatunWasi-message">
            <p>
                {{config}}
            </p>
        </div>
        <div class="buttons-group mt-1">
            <ButtonComponent type="primary" @click="emit('close')">
                Volver al juego
            </ButtonComponent>
        </div>
    </div>
</template>
<style lang="scss" src="./index.scss"></style>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, onBeforeMount } from 'vue';
import { storeToRefs } from "pinia";
import { usePachaStore } from "@/modules/pacha/store";

const pachaStore = usePachaStore();

const emit = defineEmits(["close"])


const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    }
})

const config = computed(() => {
    if(props.business.welcome !== null){
        return props.business.welcome.welcomeText;
    }

    return ''
})

const username = computed(() => {
    if(pachaStore.pachaVisited.pacha.properties.length){
        return pachaStore.pachaVisited.pacha.properties[0].user.username || pachaStore.pachaVisited.pacha.properties[0].user.myWalletAddress.slice(0, 5)
    }
    return ''
})

onBeforeMount(() => {
    if(props.business.welcome === null) emit('close');
})
</script>