<template>
    <div class="modal-service">
        <div class="modal-service-container" 
        :class="`${businessProps.environment}-${parseType}`">

            <SuccessModal v-if="resultModal.show" :data="resultModal" @close="close"/>

            <PixelFrame :styles="frameStyles" v-else>
            <div class="close-btn cursor-pointer" @click="close" v-if="closeActive">
                <img src="@/shared/assets/img/icons/close-btn-blue.png">
            </div>
                <div class="modal-service-content">
                    <component 
                    :is="config" 

                    :type="type"
                    :businessProps="businessProps"
                    :pptProps="pptProps"

                    @close="close" 
                    @result="setResultModal"
                    @pptResponse="pptResponse"
                    @closeState="(value:boolean) => {closeActive = value}"
                    />
                </div>
            </PixelFrame>
            
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { ref, defineProps, defineEmits, onMounted, computed, onUpdated, onUnmounted} from "vue";

import SuccessModal from "./SuccessModal.vue";

import PptConfig from "@/modules/game/components/PptConfig/PptConfig.vue";
import { useGameStore } from "@/modules/game/store";
const gameStore = useGameStore()
import {Howl, Howler} from 'howler';
import LoadingModal from "./LoadingModal.vue";

import BusinessConfigModal from "@/modules/pacha/components/BusinessConfigModal/BusinessConfigModal.vue";

const closeActive = ref(true);

const frameStyles = ref({
  frameBg: "gray-light",
  contentBg: "bluegray-dark",
});

const resultModal = ref({
    show: false,
    title: "",
    message: "",
    type: "",
})

const props:any = defineProps({
    type: {
        type: String,
        default: ''
    },
    resultModal: {
        type: Object,
        default: () => ({
            show: false
        })
    },
    businessProps: {
        type:Object,
        default: () => ({})
    },
    pptProps: {
        type:Object,
        default: () => ({})
    },
})

const parseType = computed(() => {
    if(props.type){
        return props.type.split(" ").join("").toLowerCase()
    }
    return ''
})

const openSound = new Howl({
src: '/assets/sounds/open_modal.ogg',
});
const closeSound = new Howl({
src: '/assets/sounds/close_modal.ogg',
});

onMounted(() => {
    if(props.resultModal.show) {
        setResultModal(props.resultModal);
    }
    openSound.play();

    openSound.volume(gameStore.musicVolume);
})

onUnmounted(() => {
    closeSound.play();

    closeSound.volume(gameStore.musicVolume);
})

const emit = defineEmits(["close", "pptResponse"])

const pptResponse = (response:boolean, state:string, amount?:number) => {
    if(amount){
        emit("pptResponse", response, state, amount);
        return
    }
    emit("pptResponse", response, state);
}

const config = computed(() => {
    if(props.type === 'loading') return LoadingModal;
    if(props.type === 'ppt') return PptConfig;
    return BusinessConfigModal;
} )

const close = () => {
    setResultModal({
        show: false,
        title: "",
        message: "",
        type: "",
    })
    emit("close");
}

const setResultModal = (data:any) => {
    resultModal.value = data
}

onUpdated(() => {
    if(props.resultModal.show) {
        setResultModal(props.resultModal);
    }
})
</script>