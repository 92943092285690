<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Calculando Pcuy</p>
        </div>
    </div>
    <div class="loader-content pl-3 pr-3 tx-white" v-else>
        <p class="tx-special-green text-center">
            Ofrendas a Wiracocha
        </p>
        <small class="tx-bluegray-lighten d-block text-center">Pacha de {{pachaStore.pachaVisited.pacha.properties[0].user.myWalletAddress.slice(0, 5)}}</small>
        <div class="content">
            <div class="content-values">
                <div class="content-values-items">
                    <span class="tx-gray">Puntos sami</span>
                    <div>
                        <img src="./img/sami_icon.png" alt="">
                        <h3 class="tx-black">
                            {{
                                guineaPigStore.guineaPigSelected.samiPoints
                            }}
                        </h3>
                    </div>
                </div>
                <div class="content-values-items">
                    <span class="tx-gray">Monedas $PCUY</span>
                    <div>
                        <img src="./img/pcuy_icon.png" alt="">
                        <h3 class="tx-black">
                            {{
                                amountInPcuy
                            }}
                        </h3>
                    </div>
                </div>
            </div>
            <p class="tx-gray text-center">
                Debes comprar un Cuy para reclamar tus SAMIS y cambiarlos a PCUY. 
            </p>
            <ButtonComponent type="primary">
                Ir a comprar un cuy
            </ButtonComponent>
            <p class="tx-gray text-center">
                Si ya tienes un cuy, cargalos desde tu Metamask
            </p>
            <button-component type="primary" class="mb-2" @action="getcuyes">
                Cargar Cuyes <img src="@/shared/assets/img/metamask.png" alt="">
            </button-component>
            <div class="text-center tx-gray">
                SU metamask debe estar configurada en la Blockchain Polygon
            </div>
        </div>
        <div class="text-center tx-yellow">
            <small>Si no cambias tus SAMIS permaneceran en tu cuenta de la web2</small>
        </div>
        <ButtonComponent type="secondary" @click="emit('close')">
            Seguir jugando
        </ButtonComponent>
    </div>
</template>

<style lang="scss" src="./index.scss">
</style>
<script lang="ts" setup>
import { ref, defineEmits, defineProps, onUpdated, onBeforeMount } from "vue";
import { usePachaStore } from "@/modules/pacha/store";
import useWeb3 from "@/shared/composables/useWeb3";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";
import axios from "axios";
import { API_URL } from '@/shared/constants'
import {
    signWiracochaTxAndReceivePcuy,
    init
} from "pachacuy-sc";
import useLogin from "@/modules/Login/composables/useLogin";
import { convertSamiToPcuy } from "pachacuy-info";
import EventsController from "@/modules/game/components/scenes/mainLevel/controllers/eventsController";
import Web3Api from "@/modules/Login/api/web3Api";
import { useUserStore } from "@/modules/user/store";
import UserApi from "@/modules/user/api/api";

const userStore = useUserStore();

const userApi = new UserApi();

const loading = ref(true);
const web3Api = new Web3Api();
const { web3Login } = useLogin()

const emit = defineEmits(["close", "result", "closeState"])

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    }
})

const { getSigner } = useWeb3();
const signer = getSigner();

const pachaStore:any = usePachaStore();
const guineaPigStore = useGuineaPigStore();
const amountInPcuy = ref('')



onBeforeMount(() => {
    useWeb3().initSmartContracts();
    if (guineaPigStore.guineaPigSelected.samiPoints <= 0) {
        emit('result', {
            show: true,
            title: "¡Ups!",
            message: "No tienes puntos Sami para canjear",
            type: "bad",
        })
        return
    }
    getAmountInPcuy()
})



const getAmountInPcuy = async() => {
    try {
        emit('closeState',false)
        const resp = await convertSamiToPcuy(
            guineaPigStore.guineaPigSelected.samiPoints
        )
        amountInPcuy.value = resp
    } catch (error:any) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : 'Ocurrió un error, por favor intenta de nuevo',
            type: "bad",
        })
    }
    loading.value = false
}

const getcuyes = async () => {
    const address = await web3Api.connectToMetamask();
    userStore.setAddress(address[0]);
    if(userStore.address){
        await web3Login(userStore.address)
        const user = await userApi.getUserWeb3(userStore.accessTokenWeb3)
        guineaPigStore.setGuineaPigs(user.data.avatars);
        emit('close')
        guineaPigStore.openModalSend = true

        const walletData = await web3Api.getWalletData(userStore.address);
        userStore.setCuyWeb3(walletData.guineaPig);
        guineaPigStore.guineaPigsData.map((cuy: any) => {
            guineaPigStore.setDaysUntilDeath(cuy.id)
        })
    }
}

</script>