<template>
    <component :is="component" 
    :businessId="props.businessProps.businessId" 
    :business="business"
    :tokenId="props.businessProps.tokenId"
    :winner="props.businessProps.misayWasiWinner"
    @close="emit('close')" 
    @result="(result:any)=>emit('result',result)"
    @closeState="(result:any)=>emit('closeState',result)"/>
</template>

<script lang="ts" setup>
import { defineProps, computed, defineEmits, onMounted } from "vue";
import { usePachaStore } from "@/modules/pacha/store";
import { storeToRefs } from "pinia";

import TatacuyConfig from "./modules/tatacuy/TatacuyConfig.vue";
import TatacuyContent from "./modules/tatacuy/TatacuyContent.vue";
import TatacuyStats from "./modules/tatacuy/TatacuyStats.vue";
import HatunWasiConfig from "./modules/hatunWasi/HatunWasiConfig.vue";
import HatunWasiContent from "./modules/hatunWasi/HatunWasiContent.vue";
import ChakraConfig from "./modules/chakra/ChakraConfig.vue";
import ChakraContent from "./modules/chakra/ChakraContent.vue";
import ChakraStats from "./modules/chakra/ChakraStats.vue";
import WiracochaContent from "./modules/wiracocha/WiracochaContent.vue";
import QhatuWasiConfig from "./modules/qhatuWasi/QhatuWasiConfig.vue";
import QhatuWasiContent from "./modules/qhatuWasi/QhatuWasiContent.vue";
import QhatuWasiStats from "./modules/qhatuWasi/QhatuwasiStats.vue";
import MisayWasiConfig from "./modules/misayWasi/MisayWasiConfig.vue";
import MisayWasiContent from "./modules/misayWasi/MisayWasiContent.vue";
import MisayWasiStats from "./modules/misayWasi/MisayWasiStats.vue";
import MisayWasiResult from "./modules/misayWasi/MisayWasiResult.vue";

const pachaStore = usePachaStore();
const { pachaBusiness } = storeToRefs(pachaStore);

const props:any = defineProps({
    businessProps: Object,
    type: String,
})

const emit = defineEmits(["close", "result", "closeState"])

const component = computed(() => {
    if(parseType.value && props.businessProps.environment) {
        return comp[parseType.value][props.businessProps.environment]
    }
    return null;
} )

const parseType = computed(() => {
    if(props.type){
        return props.type.split(" ").join("").toLowerCase()
    }
    return ''
})

const business = computed(() => {
    const thisBusiness = pachaBusiness.value
    .find(business => parseInt(business.id) === props.businessProps.businessId);
    return thisBusiness
})

const comp:any = {
    tatacuy: {
        game: TatacuyContent,
        config: TatacuyConfig,
        stats: TatacuyStats
    },
    hatunwasi: {
        game: HatunWasiContent,
        config: HatunWasiConfig
    },
    chakra: {
        game: ChakraContent,
        config: ChakraConfig,
        stats: ChakraStats
    },
    wiracocha: {
        game: WiracochaContent,
    },
    qhatuwasi: {
        config: QhatuWasiConfig,
        game: QhatuWasiContent,
        stats: QhatuWasiStats
    },
    misaywasi: {
        config: MisayWasiConfig,
        game: MisayWasiContent,
        stats: MisayWasiStats,
        result: MisayWasiResult
    },
}
</script>