import { useUserStore } from "@/modules/user/store";
import Api from "../api/api";
import Web3Api from "../api/web3Api";
import GuineaPigApi from "@/modules/guineaPigSelection/api/api";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";
import { usePachaStore } from "@/modules/pacha/store";
import UserApi from "@/modules/user/api/api";
import usePachaComposable from "@/modules/pacha/composables/pachaComposables";
import { ref } from "vue";
import router from "@/shared/router";

export default () => {
  const api = new Api();
  // const web3Api = new Web3Api();
  const userStore = useUserStore();
  const guineaPigApi = new GuineaPigApi();
  const guineapigStore = useGuineaPigStore();
  const userApi = new UserApi();
  const pachaStore = usePachaStore();
  const { getPachas, getPachaById } = usePachaComposable();
  const publicPachas:any = ref([]);

  const handleLogin = async (email:string, password:string) => {
    try {
      const islogin: any = await api.login(email, password);
      userStore.setAccessToken(islogin.data.access_token);
    } catch (error) {
      console.log(error);
      // const userRegister = await api.register({
      //   email: email,
      //   password_: password,
      // });
      // if (userRegister) {
      //   const islogin = await api.login(email, password);
      //   userStore.setAccessToken(islogin.data.access_token);
      // }
    }
    await getuserData()
  };

  const web3Login = async (address:string) => {
    try {
      const islogin: any = await api.loginWeb3(address);
      userStore.setAccessTokenWeb3(islogin.data.access_token);
    } catch (error) {
      console.log(error);
      const userRegister = await api.registerWeb3({
        myWalletAddress: address,
        password: address,
      });
      if (userRegister) {
        const islogin = await api.loginWeb3(address);
        userStore.setAccessTokenWeb3(islogin.data.access_token);
      }
    }
  }

  const connectToMetamask = async () => {
    // const address = await web3Api.connectToMetamask();
    // userStore.setAddress(address[0]);
  };

  const getWalletData = () => {
    console.log("hola");
  };

  const goToPacha = async () => {
    const pacha = await getPachaById(publicPachas.value[0].id);
    pachaStore.setPachaVisited(pacha.data);
    router.push({
      name: 'game',
    })
  };

  const getuserData = async () => {
    const user = await userApi.getUser()
    const lastAvatar = user.data.avatars[user.data.avatars.length - 1];
    guineapigStore.setGuineaPigSelected(lastAvatar)
    userStore.setAddress(user.data.email);
    userStore.setUserData(user.data);
    await getPachas();
    publicPachas.value = pachaStore.allPachas
    goToPacha()
  }

  return {
    handleLogin,
    connectToMetamask,
    getWalletData,
    web3Login,
    getuserData
  };
};
