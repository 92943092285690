<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando...</p>
        </div>
    </div>

    <div class="misay-wasi-content pl-3 pr-3" v-else>
        <p class="tx-special-green text-center mb-2">Buy a Ticket at the Casino</p>
        <h2 class="tx-yellow text-center fn-pixica-bold mb-1">
            Prize: {{data.rafflePrize}} $USDC
        </h2>
        <ul class="tx-bluegray-lighten data">
            <li>
                Participants:
                <b class="tx-white">{{data.participants}}</b>
            </li>
            <li>
                Ticket price:
                <b class="tx-white">{{data.ticketPrice}} $PCUY</b>
            </li>
            <li>
                Raffle Date:
                <b class="tx-white">{{data.campaignEndDate}} 10:00 am (GMT-5)</b>          
            </li>
        </ul>
        <div class="options mt-1 row gap-1">
            <div class="form-group col-6">
                <label for="">Number of tickets:</label>
                <div class="form-control">
                    <input
                        name="tokens"
                        type="number"
                        placeholder="1"
                        v-model.number="quantity"
                        min="1"
                    />
                </div>
            </div>
            <div class="form-group col-6">
                <label for="">Total to pay:</label>
                <div class="form-control">
                    <input
                        name="tokens"
                        type="text"
                        placeholder="$PCUY"
                        readonly
                        :value="`${data.ticketPrice * quantity} $PCUY`"
                    />
                </div>
            </div>
        </div>
        <small class="mt-1 d-block tx-danger" v-if="isFloat">La cantidad debe ser un número entero</small>
        <div>
            <small class="tx-danger">
                The raffle runs automatically. Two winners will receive in their wallets 50% each of the amount accumulated in the casino.
            </small>
        </div>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @action="purchaseTicket" :disabled="loading || isFloat">
                Pay with $PCUY
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="emit('close')">
                Cancel
            </ButtonComponent>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { computed, defineEmits, defineProps, onMounted, ref } from "vue";
import useWeb3 from "@/shared/composables/useWeb3";
import { purchaseTicketFromMisayWasi, getMisayWasiWithUuid } from "pachacuy-sc";
import { format } from "date-fns";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";
import useLogin from "@/modules/Login/composables/useLogin";
import { log } from "console";

const { getWalletData } = useLogin()

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
    tokenId: Number
})

const emit = defineEmits(["close", "result", "closeState"])

const quantity = ref(1)
const data = ref({})
const loading = ref(false)

onMounted(()=> {
    getMisayWasiData()
})

const { getSigner } = useWeb3();
const signer = getSigner();

const isFloat = computed(() => {
    return quantity.value === quantity.value && quantity.value % 1 !== 0;
})

const purchaseTicket = async () => {
    loading.value = true
    try {
        emit('closeState',false)
        await purchaseTicketFromMisayWasi(
            signer,
            props.tokenId || parseInt(props.business.businessTokenId),
            quantity.value
        )
        await getWalletData()
        emit('result', {
            show: true,
            title: "¡Muy bien!",
            message: "Ya estás participando de la rifa de este Misay Wasi",
            type: "success",
        })
    } catch (error:any) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : 'Ocurrió un error, por favor intenta de nuevo',
            type: "bad",
        })
    }
}
const guineaPigStore = useGuineaPigStore();
const getMisayWasiData = async () => {
    const resp = await getMisayWasiWithUuid(
        props.tokenId || parseInt(props.business.businessTokenId)
    )

    const endDate = new Date(parseInt(resp.campaignEndDate.toString())* 1000)
    const endDateFormat = endDate.valueOf() + endDate.getTimezoneOffset() * 60 * 1000

    data.value = {
    campaignEndDate: 
    format(endDateFormat, "dd/MM/yyyy. "),
    campaignStartDate: resp.campaignStartDate.toString(),
    creationDate: resp.creationDate.toString(),
    hasMisayWasi: resp.hasMisayWasi,
    isCampaignActive: resp.isCampaignActive,
    participants: resp.listOfParticipants.length,
    misayWasiPrice: resp.misayWasiPrice.toString() / 10 ** 18,
    misayWasiUuid: resp.misayWasiUuid.toString(),
    numberTicketsPurchased: resp.numberTicketsPurchased.toString(),
    owner: resp.owner,
    pachaUuid: resp.pachaUuid.toString(),
    rafflePrize: resp.rafflePrize.toString() / 10 ** 6,
    ticketPrice: resp.ticketPrice.toString() / 10 ** 18,
    ticketUuid: resp.ticketUuid.toString(),
    }
    guineaPigStore.setDaysUntilDeath(guineaPigStore.guineaPigSelected.id)
}
</script>

