import { defineStore } from "pinia";
import { useUserStore } from "@/modules/user/store/index";
// import { getPachaWithUuid } from "pachacuy-sc";

export const usePachaStore = defineStore('pacha',{
    state: () => ({
        pachaVisited: {
            pacha: {
                id: 0,
                pachaTokenId: 0,
            }
        },
        allPachas: [],
        specialBoxes: {},
        userPachas: [],
        pachaBusiness: [],
        pachaPases: [],
    }),
    actions: {
        setPachaVisited(pachaVisited: any) {
            this.pachaVisited = pachaVisited;
            // Object.values(this.pachaVisited).map(async(pacha:any)=>{
            //     if(pacha.pachaTokenId !== null){
            //         const pachaData = await getPachaWithUuid(pacha.pachaTokenId);
            //         pacha.properties[0].privacity = pachaData.isPublic ? "public" : "private"
            //         pacha.properties[0].price =
            //         pachaData.typeOfDistribution.toString() === '1' 
            //         ? 0 : parseInt(pachaData.pachaPassPrice.toString())/10**18;
            //     }
            // })
        },
        setAllPachas(allPachas: any) {
            this.allPachas = allPachas;
        },
        setSpecialBoxes(specialBoxes: any) {
            this.specialBoxes = specialBoxes;
        },
        setUserPachas(userPachas: any) {
            // const userStore = useUserStore();
            // this.userPachas = <any>userStore.walletData.pachas.map((pacha: any) => {
            //     return userPachas.find((userPacha: any) => userPacha.pacha.pachaTokenId === pacha) || []
            // }) || [];
            // this.userPachas.map(async (pacha:any) => {
            //     const pachaData = await getPachaWithUuid(pacha.pacha.pachaTokenId);
            //     pacha.privacity = pachaData.isPublic ? "public" : "private"
            // })
        },
        setPachaBusiness(pachaBusiness: any) {
            this.pachaBusiness = pachaBusiness;
        },
        setPachaPases(pachaPases: any) {
            this.pachaPases = pachaPases;
        },
    }
})