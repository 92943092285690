import axios from "axios";
import { useUserStore } from '@/modules/user/store/index'
import {API_URL} from '@/shared/constants/'

export default class GuineaPigApi {
  Token: any;
  constructor() {
    this.Token = useUserStore()
  }
  public async createAvatar(data: any) {
    // console.log(this.Token)
    localStorage.setItem("USER_ID", String(data.userId))

    const response = await axios({
      method: "POST",
      url: `${API_URL}/avatars`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.Token.accessToken}`,
      },
      data: data,
    });
    return response;
  }

    public async getAvatars(cuyTokenId: number) {
        const response = await axios({
            method: "GET",
            url: `${API_URL}/avatars/${cuyTokenId}/cuyTokenId`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.Token.accessToken}`,
            },
        });
        return response;
    }

    public async lastAvatar(cuyTokenId: number) {
        const response = await axios({
            method: "PUT",
            url: `${API_URL}/avatars/last/${cuyTokenId}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.Token.accessToken}`,
            },
        });
        return response;
    }

    public async sendSamis(samis:number, id1:number, id2:number) {
        const response = await axios({
            method: "PUT",
            url: `${API_URL}/avatars/transferSamis/${id1}/${id2}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.Token.accessToken}`,
            },
        });
        return response;
    }
  
}
