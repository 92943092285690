<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando compra</p>
        </div>
    </div>
    <div class="chakra-content pl-3 pr-3 tx-white" v-else>
        <p class="tx-special-green text-center mb-2">
            Venta de cultivos de Username
        </p>

        <div class="chakra-content-options">
            <div class="chakra-content-item">
                <span>{{itemName}}</span>
                <img :src="config.imageFood+'.png'" alt="">
                <small>Precio: {{parseFloat(config.priceUnitPcuys)}}</small>
            </div>
            <div class="chakra-content-data">
                <small>
                    Tipo de cultivo: 
                    <b class="tx-yellow food-type">{{itemName}}</b>
                </small>
                <div class="chakra-content-data-quantity mt-1">
                    <small>Unidades:</small>
                    <div class="d-flex mt-1">
                        <button @click="setQuantity(quantity - 1)">
                            <img src="./img/btn_minus.png" alt="">
                        </button>
                        <div class="form-group">
                            <div class="form-control">
                                <input type="text" v-model="quantity">
                            </div>
                        </div>
                        <button @click="setQuantity(quantity + 1)">
                            <img src="./img/btn_plus.png" alt="">
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <label>Total a pagar:
                    </label>
                    <div class="form-control">
                        <input type="text" placeholder="$PCUY" :value="`${price} $PCUY`" readonly>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons-group mt-1">
            <ButtonComponent type="primary" @action="purchaseFood">
                Comprar cultivo
            </ButtonComponent>
            <ButtonComponent type="secondary" @click="emit('close')">
                Cancelar
            </ButtonComponent>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { computed, ref, defineEmits, defineProps, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { usePachaStore } from "@/modules/pacha/store";
import useWeb3 from "@/shared/composables/useWeb3"; 
import axios from "axios";
import { API_URL } from '@/shared/constants'
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";
import usePachaComposable from '@/modules/pacha/composables/pachaComposables'
import useLogin from "@/modules/Login/composables/useLogin";
import { 
    purchaseFoodFromChakra,
 } from "pachacuy-sc";

const pachaStore = usePachaStore();
const { pachaVisited } = storeToRefs(pachaStore);
const { getPachaBusiness } = usePachaComposable()
const { getWalletData } = useLogin()

const loading = ref(false);

const emit = defineEmits(["close", "result", "closeState"])

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    }
})

const quantity = ref(1)
const price = computed(() => {
    return parseFloat(config.value.priceUnitPcuys) * quantity.value;
})

const setQuantity = (value:number) => {
    if(value < 1) return
    if(value > config.value.remainingFood) return
    quantity.value = value
}

const config = computed(() => {

    return props.business.food;

})

const itemName = computed(() => {
    return config.value.typeFood.split('_').join(' ')
});

const { getSigner } = useWeb3();
const signer = getSigner();

const guineaPigStore = useGuineaPigStore();

const purchaseFood = async() => {
    loading.value = true;
    emit('closeState',false)
    let response;
    try {
        response = await purchaseFoodFromChakra(
            signer,
            parseInt(props.business.businessTokenId),
            quantity.value,
            parseInt(guineaPigStore.guineaPigSelected.cuyTokenId)
        )
        // console.log(response)
    } catch (error:any) {
         emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : 'Ocurrió un error, por favor intenta de nuevo',
            type: "bad",
        })
        return
    }
    if(response){
        loading.value = false;
        emit('closeState',true)
        await getWalletData()
        await getPachaBusiness(pachaVisited.value.pacha.id)
        guineaPigStore.setDaysUntilDeath(guineaPigStore.guineaPigSelected.id)
        emit('result', {
            show: true,
            title: "¡Muy bien!",
            message: "Has realizado la compra de nuevos cultivos exitosamente",
            type: "success",
        })
    }
}
</script>