<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando...</p>
        </div>
    </div>
    <div class="pl-3 pr-3" v-else>
        <p class="tx-special-green text-center">Configuración de Qhatu Wasi</p>
        <div>
            <small class="tx-bluegray-lighten">1/2 - Contenido de la campaña</small>
            <hr class="br-bluegray-lighten mb-1">
        </div>
        <div v-if="!nextStep">
            <div class="form-group">
                <label for="">Banner de la campaña - jpg, png, gif (444x240)</label>
                <div class="form-control">
                    <input name="tokens" type="file" placeholder="Subir una imagen" class="d-none" id="image"
                        @change="setImage" accept="image/png, image/gif, image/jpeg"/>
                    <label for="image" class="form-control-content">
                        {{
                                fileName || 'Subir una imagen'
                        }}
                    </label>
                </div>
            </div>
            <small class="d-block tx-danger" v-if="invalidSize">La imagen debe ser 444x240</small>
            <div class="row gap-1">
                <div class="form-group col-12">
                    <label for="">Dirección de página web</label>
                    <div class="form-control">
                        <input name="tokens" type="text" placeholder="Inserta enlace" v-model="url" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="form-group mb-2">
                <label for="">Saldo total de la campaña ($PCUY)</label>
                <div class="form-control">
                    <input name="tokens" type="number" placeholder="$PCUY" v-model="total" />
                </div>
            </div>
            <div class="row gap-1">
                <div class="form-group col-6">
                    <label for="">Premio por vista completa</label>
                    <div class="form-control">
                        <input name="tokens" type="number" placeholder="$PCUY" v-model="reward" />
                    </div>
                </div>
                <div class="form-group col-6">
                    <label for="">Cantidad max. de premios</label>
                    <div class="form-control">
                        <input name="tokens" type="number" placeholder="0" readonly v-model="max" />
                    </div>
                    <small class="mt-1 d-block tx-danger" v-if="isFloat(max)">El valor debe ser entero</small>
                </div>
            </div>
        </div>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @action="handleClick" :disabled="invalidSize">
                {{
                    update ? 'Actualizar datos' :
                    nextStep ? 'Confirmar datos' : 'Siguiente paso'
                }}
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="emit('close')">
            {{
                    update ? 'Mantener' : 'Configurarlo luego'
            }}
            </ButtonComponent>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, defineEmits, defineProps, computed, onMounted } from "vue";
import axios from "axios";
import { API_URL } from "@/shared/constants/";
import { startQhatuWasiCampaign } from "pachacuy-sc";
import { convertPcuyToSami } from "pachacuy-info";
import useWeb3 from "@/shared/composables/useWeb3";
import { utils } from "ethers";
import useLogin from "@/modules/Login/composables/useLogin";

const { getWalletData } = useLogin()

const nextStep = ref(false);

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const emit = defineEmits(["close", "result", "closeState"])

const image = ref("");
const url = ref("");
const total = ref(0);
const reward = ref(0);
const fileName = ref("");
const loading = ref(false);
const update = ref(false);
const imageSize = ref({
    width: 0,
    height: 0
})
const invalidSize = ref(false);

const max = computed(() => {
    const result = total.value / reward.value;
    return result === Infinity ? 0 : result || 0;
})

const setImage = (e: any) => {
    const target = e.target;
    const file = target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e:any) => {
        image.value = reader.result;

        const img = new Image(); 
        img.onload = function(){
            imageSize.value = {
                width: img.width,
                height: img.height
            }
            if(imageSize.value.width == 444 && imageSize.value.height == 240){
                invalidSize.value = false;
            }else{
                invalidSize.value = true;
            }
        };

        img.src = image.value; 
    };
    fileName.value = file.name;

}

const totalSami = ref(0);
const rewardSami = ref(0);

const { getSigner } = useWeb3();
const signer = getSigner();

const handleClick = async () => {
    if (!nextStep.value && !update.value) {
        nextStep.value = true;
        return
    }
    if (!url.value) {
        return
    }
    if (!total.value && !update.value) {
        return
    }
    if (!reward.value && !update.value) {
        return
    }
    if (!max.value && !update.value) {
        return
    }
    loading.value = true;
    emit('closeState',false)

    if(!url.value.includes("http://")){
        url.value = url.value = 'http://' + url.value
    }

    if(update.value){
        try {
            const resp = await axios({
                method: "put",
                url: `${API_URL}/qhatu-wasi/advertising/${props.business.advertising.id}`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
                },
                data: {
                    "image": image.value,
                    "link": url.value,
                }
            })
             emit('result', {
                show: true,
                title: "¡Muy bien!",
                message: "Tu campaña ha sido actualizada con éxito",
                type: "success",
            })
        } catch (error) {
             emit('result', {
                show: true,
                title: "¡Oh no!",
                message: "Ha ocurrido un error al configurar tu Qhatu Wasi, inténtalo de nuevo",
                type: "bad",
            })
        }
        return
    }

    try {
        const totalSamiValue = await convertPcuyToSami(
            utils.parseEther(String(total.value))
        );

        const rewardSamiValue = await convertPcuyToSami(
            utils.parseEther(String(reward.value))
        );

        totalSami.value = parseFloat(totalSamiValue.toString());
        rewardSami.value = parseFloat(rewardSamiValue.toString());

    } catch (error: any) {
        // console.log(error)
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : "Ocurrió un error, intenta de nuevo",
            type: "bad",
        })
        return
    }

    try {
        const resp = await startQhatuWasiCampaign(
            signer,
            props.business.businessTokenId,
            utils.parseEther(String(total.value)),
            utils.parseEther(String(reward.value)),
        )
        try {
            await getWalletData()
            const resp = await axios({
                method: "post",
                url: `${API_URL}/qhatu-wasi/advertising`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
                },
                data: {
                    "image": image.value,
                    "link": url.value,
                    "totalPcuys": total.value,
                    "totalSamiPoints": totalSami.value,
                    "cuySamiPointsReceive": rewardSami.value,
                    "businessId": props.businessId,
                }
            })
            emit('result', {
                show: true,
                title: "¡Muy bien!",
                message: "Has comprado un Qhatu Wasi y ahora ya es parte de tu inventario",
                type: "success",
            })
        } catch (error) {
            emit('result', {
                show: true,
                title: "¡Oh no!",
                message: "Ha ocurrido un error al configurar tu Qhatu Wasi, inténtalo de nuevo",
                type: "bad",
            })
        }

    } catch (error: any) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : "Ocurrió un error, intenta de nuevo",
            type: "bad",
        })
    }
}

const isFloat = (value: number) => {
    return Number(value) === value && value % 1 !== 0;
}

onMounted(() => {
    if(props.business.advertising !== null) {
        update.value = true;
        url.value = props.business.advertising.link;
    }
})

</script>