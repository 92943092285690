<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando...</p>
        </div>
    </div>
    <div class="tx-white pl-3 pr-3" v-else>
        <p class="tx-special-green text-center mb-2">Configuración de mi Hatun Wasi</p>
        <div class="mb-1">
            <p>Formato del comunicado:</p>
            <div class="format-container">
                <div class="format-radio-input">
                    <input type="radio" id="text"
                    value="text"
                    v-model="format" class="d-none">
                    <label for="text" class="box"></label>
    
                    <label for="text">Texto</label>
                </div>
    
                <!-- <div class="format-radio-input">
                    <input type="radio" id="video"
                    value="video"
                    v-model="format" class="d-none">
                    <label for="video" class="box"></label>
    
                    <label for="video">Video</label>
                </div> -->
            </div>
        </div>

        <div v-if="format === 'text'">
            <div class="form-group" >
                <label for="">Edita el mensaje/comunicado para tus visitantes:
                </label>
                <div class="form-control">
                    <textarea name="" id="" rows="8" v-model="message"></textarea>
                </div>
            </div>
            <small class="tx-bluegray-lighten">* Recuerda que tu mensaje de bienvenida será visto por todos los jugadores que visiten tu Pacha.</small>
        </div>
        <!-- <div v-else>
            <div class="form-group">
                <label>Sube un video para tus visitantes:
                </label>
                <div class="form-control">
                    <input type="text" name="" id="file" v-model="message">
                </div>
            </div>
            <small class="tx-bluegray-lighten">* Recuerda que tu video será visto por todos los jugadores que visiten tu Pacha.</small>
        </div> -->

        <div class="buttons-group mt-1">
            <ButtonComponent type="primary" @action="handleClick" :disabled="!message">
                Confirmar
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="emit('close')">
                Configurarlo luego
            </ButtonComponent>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { ref, defineProps, defineEmits, onBeforeMount } from "vue";
import axios from "axios";
import { API_URL } from '@/shared/constants'
import { createHatunWasi, updateHatunWasi } from "./api";

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const loading = ref(false);

const emit = defineEmits(["close", "result"])

const format = ref("text");
const message = ref("");

onBeforeMount(() => {
    if (props.business.welcome !== null) {
        message.value = props.business.welcome.welcomeText;
    }
})

const handleClick = async () => {
    loading.value = true;
    try {
        if(props.business.welcome !== null){
            updateHatunWasi(message.value, props.business.welcome.id);
        }else{
            createHatunWasi(message.value, props.businessId);
        }
        loading.value = false;
        emit('result', {
            show: true,
            title: "¡Muy bien!",
            message: "Has configurado tu Hatun Wasi de forma correcta y ahora es parte de tu Pacha",
            type: "success",
        })
    } catch (error) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: "Ha ocurrido un error al configurar tu Hatun Wasi, inténtalo de nuevo",
            type: "bad",
        })
    }
}
</script>