<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="loading">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando...</p>
        </div>
    </div>

    <div class="pl-3 pr-3 tx-white pb-2 pt-2" v-else>
        <p class="tx-special-green text-center mb-2">Información acerca del Qhatu Wasi</p>
        <ul class="tx-bluegray-lighten data">
            <li>
                Saldo total de la campaña:
                <b class="tx-white">{{data.totalSami}} Puntos Sami</b>
            </li>
            <li>
                Premios por entregar:
                <b class="tx-white">{{
                        data.totalSami / data.avatarSami
                }}</b>
            </li>
            <li>
                Valor de cada premio:
                <b class="tx-white">{{data.prizePerView}} Puntos Sami</b>
            </li>
            <li>
                Cuyes que abrieron la publicidad:
                <b class="tx-white">{{
                    data.avatarOpenAdvertising
                }}</b>
            </li>
            <li>
                Cuyes recompensados:
                <b class="tx-white">{{
                    data.rewardedAvatars
                }}</b>
            </li>
            <li>
                Fecha de inicio:
                <b class="tx-white">{{
                    format(new Date(data.start), "dd/MM/yyyy")
                }}</b>
            </li>
        </ul>
        <div class="buttons-group mt-2">
            <ButtonComponent type="primary" @action="emit('close')">
                Volver al juego
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="cancelCampain">
                Cancelar premiación
            </ButtonComponent>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, onMounted, ref } from "vue";
import useWeb3 from "@/shared/composables/useWeb3";
import { getQhatuWasiWithUuid } from "pachacuy-sc";
import { format } from "date-fns";
import axios from "axios";
import { API_URL } from "@/shared/constants";
import { useGuineaPigStore } from "@/modules/guineaPigSelection/store";

const guineaPigStore = useGuineaPigStore();

const props:any = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const emit = defineEmits(["close", "result"])

const data:any = ref({})
const loading = ref(true)

onMounted(async() => {
    await getQhatuWasiData()
    await getStats()
})

const getQhatuWasiData = async () => {
    const resp = await getQhatuWasiWithUuid(props.business.businessTokenId)
    data.value = {
        ...data.value,
        pcuyForCurrentCampaign: resp.pcuyForCurrentCampaign.toString(),
        prizePerView: resp.prizePerView.toString(),
        qhatuWasiPrice: resp.qhatuWasiPrice.toString(),
        samiPointsToGiveAway: resp.samiPointsToGiveAway.toString(),
        totalPcuyDeposited: resp.totalPcuyDeposited.toString(),
    }
    loading.value = false
}

const getStats = async () => {
    const resp = await axios({
        method: "GET",
        url: `${API_URL}/qhatu-wasi/advertising/${props.business.advertising.id}/statistics`,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
        }
    })
    data.value = {
        ...data.value,
        ...resp.data
    }
}

const cancelCampain = async () => {
    loading.value = true
    try {        
        const resp = await axios({
            method: "PUT",
            url: `${API_URL}/qhatu-wasi/advertising/${props.business.advertising.id}/${guineaPigStore.guineaPigSelected.id}`,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("TOKEN")}`
            },
        })
        guineaPigStore.updateCuySamiPoints(
            guineaPigStore.guineaPigSelected.samiPoints +
            (data.value.totalSami - (data.value.rewardedAvatars * data.value.prizePerView))
        )
        emit('result', {
            show: true,
            title: "¡Muy bien!",
            message: `La camapaña ha sido cancelada con éxito.`,
            type: "success",
        })
    } catch (error) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: `Ha ocurrido un error al cancelar la campaña.`,
            type: "bad",
        })
    }
}
</script>