<template>
    <div class="loader-content pl-3 pr-3 tx-white pb-2 pt-2" v-if="prosesing">
        <img src="@/shared/assets/img/cuy.png" alt="" class="loading-cuy">
        <div class="loading mb-2 mt-2">
            <img src="@/shared/assets/img/loader.png" alt="">
            <p>Procesando...</p>
        </div>
    </div>
    <div class="chakra-config pl-3 pr-3 tx-white" v-else>
        <p class="tx-special-green text-center mb-2">Configuración de mi Chakra</p>
        <span>
            Tipo de cultivo: 
            <b class="tx-yellow food-type">{{ itemName }}</b>
        </span>

        <div class="food mt-1">
            <button class="btn-inventary-panel button-unset"
            v-for="(item, index) in FOOD" :key="index"
            @click="selectedFood = item.name"
            :class="activeItem(item.name)"
            >
            <div class="btn-panel-border"></div>
            <div class="btn-panel-content">
              <figure>
                <img :src="item.img">
              </figure>
            </div>
            <img src="@/modules/pacha/assets/img/selector_sprite_small.png" class="framing" alt="Framing">
          </button>
        </div>

        <div class="form-group">
            <label>Precio de cada unidad ($PCUY):
            </label>
            <div class="form-control">
                <input type="number" name="" id="price" v-model="price">
            </div>
        </div>

        <div class="buttons-group mt-1">
            <ButtonComponent type="primary" @action="handleClick" 
            :disabled="!(selectedFood && price)">
                Confirmar datos
            </ButtonComponent>
            <ButtonComponent type="secondary" @action="emit('close')">
                Configurarlo luego
            </ButtonComponent>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss"></style>
<script lang="ts" setup>
import { computed, ref, defineProps, defineEmits, onMounted } from "vue";
import FOOD from "./mocks/food";
import axios from "axios";
import { API_URL } from '@/shared/constants'
import { 
    updateFoodPriceAtChakra
} from "pachacuy-sc";
import useWeb3 from "@/shared/composables/useWeb3";
import { utils } from "ethers";
import useLogin from "@/modules/Login/composables/useLogin";

const { getWalletData } = useLogin()

const props = defineProps({
    businessId: {
        type: Number
    },
    business: {
        type: Object
    },
})

const emit = defineEmits(["close", "result", "closeState"])

const selectedFood = ref('');
const price = ref(0);
const prosesing = ref(false)

const activeItem = (itemName:string) => {
    if(itemName === selectedFood.value) return 'focus'
    return ''
};

const itemName = computed(() => {
    return selectedFood.value.split('_').join(' ')
});

const { getSigner } = useWeb3();
const signer = getSigner();

onMounted(() => {
    if(props.business.food !== null) emit('close')
})

const handleClick = async () => {
    prosesing.value = true;
    emit('closeState',false)
    let response;
    try{
        response = await updateFoodPriceAtChakra(
            signer,
            parseInt(props.business.businessTokenId),
            utils.parseEther(String(price.value))
        )
        await getWalletData()
    }catch (error:any) {
         emit('result', {
            show: true,
            title: "¡Oh no!",
            message: error.data ? error.data.message : 'Ocurrió un error, por favor intenta de nuevo',
            type: "bad",
        })
        return
    }
    try {
        if(response){
            const resp = await axios({
                method: "post",
                url: `${API_URL}/chakra/foods`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`
                },
                data: {
                    businessId: props.businessId,
                    "priceUnitPcuys": price.value,
                    "typeFood": selectedFood.value
                }
            })
            if(resp.data){
                emit('result', {
                    show: true,
                    title: "¡Muy bien!",
                    message: "Has configurado tu Chakra de forma correcta y ahora es parte de tu Pacha",
                    type: "success",
                })
            }
        }
    } catch (error) {
        emit('result', {
            show: true,
            title: "¡Oh no!",
            message: "Ha ocurrido un error, por favor inténtalo de nuevo",
            type: "bad",
        })
    }
}

</script>