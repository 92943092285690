import { ref } from "vue";
import { providers } from "ethers";
import { useUserStore } from "@/modules/user/store";
import { init } from "pachacuy-sc";
import { BigNumber } from "ethers";
import EventsController from "@/modules/game/components/scenes/mainLevel/controllers/eventsController";
import axios from "axios";
import { API_URL } from "../constants";

const useWeb3 = () => {
  const ethereum = (window as any).ethereum;
  const userStore = useUserStore();
  
  async function initSmartContracts() {
    if(ethereum){
      const [NFT, PurchaseContract, TatacuyContract, WiracochaContract] = init(ethereum);

      PurchaseContract.on("GuineaPigPurchaseFinish", (_account, price, _guineaPigId, _uuid, _raceAndGender) => {
        // console.log("GuineaPigPurchaseFinish");
        EventsController.getInstance().emiter.emit("finishPurchase", _account, price, _guineaPigId, _uuid, _raceAndGender);
      })

      PurchaseContract.on('PurchaseFoodChakra', async(
        chakraUuid,
        amountOfFood,
        availableFood,
        chakraOwner,
        pcuyReceived,
        pcuyTaxed,
        tax,
        balanceOwner,
        balanceConsumer
      ) => {
        if(chakraOwner.toString().toLowerCase() === userStore.address.toLowerCase()) {
          userStore.setPcuy(balanceOwner.toString() / 10 ** 18)
        }
        await axios({
          method: 'post',
          url: `${API_URL}/chakra/food-sales/remaining`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`
          },
          data: {
            businessTokenUUID: chakraUuid.toString(),
            remainingFood: parseInt(availableFood.toString()),
          }
        })
      })

      PurchaseContract.on("PurchaseLand", (_account, uuid, landPrice, _location, custodianWallet) => {
        EventsController.getInstance().emiter.emit("PurchaseLand", _account, uuid, landPrice, _location, custodianWallet)
      })

      PurchaseContract.on('PurchasePachaPass', (
        account,
        pachaOwner,
        pachaUuid,
        pachaPassUuid,
        price,
        pcuyReceived,
        pcuyTaxed,
        balanceOwner,
        balanceConsumer
      ) => {
        if(pachaOwner.toString().toLowerCase() === userStore.address.toLowerCase()) {
          userStore.setPcuy(balanceOwner.toString() / 10 ** 18)
        }
      })
      PurchaseContract.on('PurchaseTicket', (
        misayWasiOwner,
        misayWasiUuid,
        ticketUuid,
        balanceOwner,
        balanceConsumer
      ) => {
        if(misayWasiOwner.toString().toLowerCase() === userStore.address.toLowerCase()) {
          userStore.setPcuy(balanceOwner.toString() / 10 ** 18)
        }
      })
      
      TatacuyContract.on("TatacuyTryMyLuckResult", (account, hasWon, prizeWinner, likelihood, pachaUuid, tatacuyUuid, pachaOwner, idFromFront)=>{
        EventsController.getInstance().emiter.emit("TatacuyTryMyLuckResult", account, hasWon, prizeWinner, likelihood, pachaUuid, tatacuyUuid, pachaOwner, idFromFront)
      })
      WiracochaContract.on(
        "WiracochaExchange",
        (
            exchanger,
            pachaOwner,
            pachaUuid,
            amountPcuy,
            totalPcuyBalance,
            samiPoints,
            ratePcuyToSami,
            idFromFront
        ) => {
          EventsController.getInstance().emiter.emit("WiracochaExchange", exchanger, pachaOwner, pachaUuid, amountPcuy, totalPcuyBalance, samiPoints, ratePcuyToSami, idFromFront)
        })
    }
    
  }

  const getSigner = () => {
    if(ethereum){
      const provider = new providers.Web3Provider(ethereum);
      const signer = provider.getSigner('0xDf584464CCc165F612219849cF133dF8CFDaC0Ba');
      return signer;
    }
  };
  // const validateApprove = async (amount: number) => {
  //   const _allowance = await allowance(userStore.address);
  //   if (amount * 1000000000000000000 <= parseInt(_allowance.toString())) {
  //     return true;
  //   }
  //   return false;
  // };

  const approveAmount = async (amount: number) => {
    // const signer = getSigner();
    // const value = BigNumber.from(`${amount}000000000000000000`);
    // const resp = await approveBusd(value, signer);
    // return resp;
  };

  return {
    initSmartContracts,
    getSigner,
    approveAmount,
  };
};

export default useWeb3;