export default [
    {
        name: 'camote',
        img: '/assets/food/CAMOTE.png'
    },
    {
        name: 'hojas_de_coca',
        img: '/assets/food/HOJAS_DE_COCA.png'
    },
    {
        name: 'maiz_choclo',
        img: '/assets/food/MAIZ_CHOCLO.png'
    },
    {
        name: 'maiz_morado',
        img: '/assets/food/MAIZ_MORADO.png'
    },
    {
        name: 'nabo',
        img: '/assets/food/NABO.png'
    },
    {
        name: 'papa',
        img: '/assets/food/PAPA.png'
    },
    {
        name: 'zanahoria',
        img: '/assets/food/ZANAHORIA.png'
    },
]

