import './process-polyfill';
import { createApp } from 'vue'
import App from './App.vue'
import router from './shared/router'
import '@/shared/assets/scss/index.scss'
import { createPinia } from 'pinia'
import BuyModal from './shared/components/buyModal/BuyModal.vue'
import PixelFrame from './shared/components/PixelFrame/PixelFrame.vue'
import ButtonComponent from './shared/components/ButtonComponent/ButtonComponent.vue'
import { createI18n } from 'vue-i18n'
import { home } from './i18n/home'

const i18n = createI18n({
  locale: 'es',
  messages: home
})

const app = createApp(App)
const pinia = createPinia();

app.component('BuyModal', BuyModal)
app.component('PixelFrame', PixelFrame)
app.component('ButtonComponent', ButtonComponent)

app.use(pinia);
app.use(router);
app.use(i18n)

router.isReady().then(() => {
  app.mount("#app");
});