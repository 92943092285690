import { defineStore } from "pinia";
import { getGuineaPigWithUuid } from "pachacuy-sc";

export const useGuineaPigStore = defineStore("guineaPigs", {
  state: () => ({
    guineaPigsData: (<any>[]),
    guineaPigSelected: {
      cuy: {
        spriteIdle: "",
      },
      speed: 0,
      samiPoints: 0,
      id: 0,
      isGhost: false,
    },
    purchasedGuineaPig: '',
    openModalSend: false
  }),
  actions: {
    setGuineaPigs(guineaPigs: any) {
      this.guineaPigsData = guineaPigs.map((guineaPig: any) => {
        return {
          ...guineaPig,
          live:0,
          burnTime:0
        };
      });
    },
    setGuineaPigSelected(guineaPig: any) {
      this.guineaPigSelected = guineaPig;
    },
    updateCuySamiPoints(points: number) {
      this.guineaPigSelected.samiPoints = points;
    },
    async setDaysUntilDeath(id: number) {
      const cuy = this.guineaPigsData.find((guineaPig: any) => guineaPig.id === id)

      const guineaPig = await getGuineaPigWithUuid(cuy.cuyTokenId)

      const live = Math.ceil(((parseInt(guineaPig.feedingDate.toString()) * 1000) - new Date().getTime()) / (24 * 60 * 60 * 1000))

      const burnTime = Math.ceil(((parseInt(guineaPig.burningDate.toString()) * 1000) - new Date().getTime()) / (24 * 60 * 60 * 1000))

      cuy.live = live
      cuy.burnTime = burnTime

      // console.log(cuy)

      if (cuy.live <= 0) {
        cuy.isGhost = true
      }else{
        cuy.isGhost = false
      }

      if(this.guineaPigSelected.id === id){
        this.setGuineaPigSelected(cuy)
      }
    },
    setPurchasedGuineaPig(guineaPig: string){
      this.purchasedGuineaPig = guineaPig
    }
  },
});
